import React, {Component} from 'react'
import {connect} from 'react-redux';
import {addRun, removeRun, touchRuns, updateRun} from '../redux/actions'
import {inactivateRun, patchRun } from "../../../services/runs";
import StopTypingInput from "../../../shared/StopTypingInput";
import globals from '../../../config/globals'
import ArchiveAndRestore from "./ArchiveAndRestore";

class Run extends Component {


    constructor(props) {
        super(props)
        this.updateRun = this.updateRun.bind(this)
        this.inactivateRun = this.inactivateRun.bind(this)
    }

    async updateRun(id, key, text){
        const fields = { [key]: text }
        const resp = await patchRun(this.props, id, fields)
        this.props.updateRun(resp.run)
    }

    async inactivateRun(id){
        const resp = await inactivateRun(this.props, id)
        this.props.removeRun(resp.run)
    }


    render() {
        const {run, index, store} = this.props
        return (
            <div className={'cursor-pointer'} onClick={()=> window.location = globals.run_builder_navigation(run.id)} key={`${index}-run`}>
                <div className={`run-card text-left run-row no_select cursor-pointer padding-vertical-md selected`} key={index}>
                    <ArchiveAndRestore
                        run={run}
                        authenticationToken={this.props.authenticationToken}
                    />
                    <img src={window.view_data.imgs.access} className={'access-icon float-left'} />

                    <div className={'w100 run-row'}>
                        <StopTypingInput
                            id={run.id}
                            onFocus={()=>this.props.updateRun({ id: run.id })}
                            className={'category-name run-name'}
                            value={run.name}
                            timeForStop={400}
                            defaultValue={run.name}
                            onTextStop={(text)=>{
                                this.updateRun(run.id, 'name', text)
                            }}
                        />
                    <div className={'inline-block'}>
                        <select
                            className="owner-select"
                            value={run.owner}
                            onChange={(e)=>{
                                this.updateRun(run.id, 'owner', e.target.value)
                            }}
                        >
                            <option>Assign to</option>
                            {
                                store.users.map((u,i)=>(
                                    <option value={u.id} key={`user-${i}`}>{`${u.first} ${u.last}`}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className={'inline-block'}>
                        <select className={`ticket-status-select ${run.ticket_status}`}
                                value={run.ticket_status}
                                onChange={(e)=>{
                                    this.updateRun(run.id, 'ticket_status', e.target.value)
                                }}
                        >
                            <option value={'open'}>Open</option>
                            <option value={'active'}>Active</option>
                            <option value={'paused'}>Paused</option>
                            <option value={'closed'}>Closed</option>
                        </select>
                    </div>
                    <div className={'inline-block'}>
                        <select
                            className={`temperature-select ${run.temperature}`}
                            value={run.temperature}
                            onChange={(e)=>{
                                this.updateRun(run.id, 'temperature', e.target.value)
                            }}
                        >
                            <option value={'select'}>Select</option>
                            <option value={'fire'}>Fire</option>
                            <option value={'hot'}>Hot</option>
                            <option value={'warm'}>Warm</option>
                            <option value={'active'}>Active</option>
                            <option value={'cool'}>Cool</option>
                            <option value={'cold'}>Cold</option>
                            <option value={'ice'}>Ice</option>
                        </select>
                    </div>
                    {/*<input*/}
                        {/*type={'number'}*/}
                        {/*className={'weight-input'}*/}
                    {/*/>*/}
                    <div className={'launch-run-builder'} onClick={(e)=> {
                        e.stopPropagation()
                        window.location = store.runsFor ? `/runs/${run.id}?origin=runs_for` : `/runs/${run.id}`
                    }}>
                        RUN ➤


                    </div>
                    </div>
                </div>
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        store: state
    };
}

export default connect(mapStateToProps, {
    addRun,
    removeRun,
    touchRuns,
    updateRun
})(Run)
