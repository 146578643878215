import React, {Component} from 'react'
import {connect} from 'react-redux';
import {updateField, patch} from './redux/actions'
import UserNav from "../../UserNav";
import Radio from './components/Radio'
import Input from './components/Input'
import Summary from './components/Summary'
import CustomLineItems from './components/CustomLineItems'
import {defaults} from "../../utils/proposalConfig";
import globals from "../../config/globals";
import TextInput from "../../designer/Designer/TextInput";

class Body extends Component {

    constructor(props) {
        super(props)
        this.click = this.click.bind(this)
        this.changeProposalType = this.changeProposalType.bind(this)
        this.isValid = this.isValid.bind(this)
        this.state = {
            expanded: false,
            name: null,
            categoryId: (this.category || this.props.store.categories[0] || {}).id,
        }
        console.log(this.props.store)
    }

    click() {
        const {store} = this.props
        window.location = `/category/${store.category.id}/playbook/${store.playbook.id}/proposal`
    }

    get config() {
        return this.props.store.config
    }

    changeProposalType(value) {
        this.props.patch({
            ...defaults(this.config, value)
        })
    }

    get playbook() {
        return this.props.store.playbook
    }

    isValid() {
        let valid = true
        let newState = {
            msgs: [],
            nameValidationError: false,
        }

        if (!this.props.store.name) {
            newState.msgs.push('"Name" must have value')
            newState.nameValidationError = true
            valid = false
        }


        this.setState({
            ...newState,
            invalidMsg: newState.msgs.length > 0 ? `${newState.msgs.join(',')}` : ''
        })

        return valid
    }

    submit() {
        document.getElementById("proposal-form").submit()
    }

    get category() {
        return this.props.store.category
    }

    get playbooks() {
        if (this.state.categoryId) {
            console.log(this.state.categoryId)
            return this.props.store.playbooks.filter((a) => a.category_id === Number(this.state.categoryId))
        } else {
            return this.props.store.playbooks
        }
    }

    render() {

        return (
            <div className='whole-body proposal-body'>

                <form id={'proposal-form'} action="/proposal/create" method="post">

                    <input type='hidden' name='authenticity_token' value={this.props.store.authenticationToken}/>


                    <input type='hidden' name='currency' value={'USD'}/>
                    <input type='hidden' name='status' value={'NEW'}/>


                    <UserNav/>
                    <img src={window.view_data.imgs.nav_banner_sm} className={'nav-banner'}/>

                    <div>

                        <div className={'row no-margin'}>
                            <div className={'col-lg-2 col-sm-12'}></div>
                            <div className={'col-lg-8 col-sm-6 text-left margin_top_md'}>
                                {
                                    this.playbook &&
                                    <div className={'w100 cursor-pointer back-designer'}
                                         onClick={() => window.location = `/playbook/${this.playbook.id}`}>
                                        <div>← Back</div>
                                    </div>
                                }
                                <div className="proposal-page-alert margin_top_md">
                                    <b>Due to high demand, we suggest that new deployment requests should be scheduled out as far in-advance as possible.</b>
                                    <div>◦ Streamliner is currently automating the remainder of the deployment process.</div>
                                    <div>◦ In the very near future you will count deployment time in minutes not days.</div>
                                    <div>◦ Thank you for your support and understanding.</div>
                                </div>

                                {/*<h2 className="margin_top_lg">*/}
                                    {/*<div className={'section-number'}>1</div>*/}
                                    {/*How It Works*/}
                                {/*</h2>*/}

                            </div>
                        </div>
                        {/*<div>*/}
                            {/*<div className={'row no-margin'}>*/}
                                {/*<div className={'col-lg-2 col-sm-12'}></div>*/}
                                {/*<div className={'col-lg-8 col-sm-12 text-left'}>*/}
                                    {/*<div className={'relative proposal-summary-2'}>*/}
                                        {/*<img src={window.view_data.imgs.how_it_works} className="w100"/>*/}
                                    {/*</div>*/}
                                {/*</div>*/}
                            {/*</div>*/}
                        {/*</div>*/}
                    </div>
                    <div>
                        <div className={'row no-margin'}>
                            <div className={'col-lg-2 col-sm-12'}></div>
                            <div className={'col-lg-8 col-sm-12 text-left'}>
                                <h2 className="margin_top_lg">
                                    <div className={'section-number'}>1</div>
                                    Configure
                                </h2>
                            </div>
                        </div>
                    </div>


                    <div>
                        <div className={'row no-margin'}>
                            <div className={'col-lg-2 col-sm-12'}></div>
                            <div className={'col-lg-8 col-sm-12 text-left'}>
                                <div className={'relative proposal-summary-2'}>
                                    <div className="w50-proposal">
                                        {
                                            this.state.showInvalid &&
                                            <div className="danger-alert">{this.state.invalidMsg}</div>
                                        }
                                        {
                                            this.category && this.playbook &&
                                            <div className="w100">
                                                <h4 className={'margin_top_md'}>Category</h4>
                                                <div
                                                    className="w100">
                                                    <a href={`/category/${this.category.id}`}>{this.category.name}</a>

                                                    <input hidden name={'category_id'} value={this.category.id}/>
                                                    <input hidden name={'playbook_id'} value={-1}/>
                                                </div>
                                            </div>
                                        }
                                        {
                                            !this.category && !this.playbook &&
                                            <div className="w100 margin_top_lg">
                                                <h4 className={'margin_top_md'}>Category</h4>
                                                <div className="w100 margin_top_md">
                                                    <select name={'category_id'}
                                                            onChange={(e) => this.setState({categoryId: e.target.value})}>
                                                        {
                                                            this.props.store.categories.map((category, i) => {
                                                                return (<option key={i}
                                                                                value={category.id}>{category.name}</option>)
                                                            })
                                                        }
                                                    </select>
                                                    <input hidden name={'playbook_id'} value={-1}/>
                                                </div>
                                            </div>
                                        }
                                        {
                                            this.state.categoryId &&
                                            <div className="w100">
                                                <h4 className={'margin_top_md'}>Playbooks</h4>
                                                <div className="w100">

                                                    {
                                                        this.playbooks.length > 0 &&
                                                        this.playbooks.map((a)=>{
                                                            return (
                                                                <div className={'playbook-bullet'}>{a.name}</div>
                                                            )
                                                        })
                                                    }
                                                    <div className="margin_top_sm playbook-disclaimer">
                                                    Category playbooks can be added or removed in the deployment manager. Don’t worry, you can always add additional playbooks even while in testing.
                                                     </div>
                                                </div>
                                            </div>
                                        }


                                        <div className={'w100 margin_top_lg'}>
                                            <h4 className={'margin_top_md'}>Name</h4>
                                            <div className={'section'}>
                                                <div>
                                                    <b>Deployment Name</b>
                                                    <div><i>Should be unique (internal category ID, etc.)</i>
                                                    </div>
                                                </div>
                                                <div className={this.state.nameValidationError ? 'invalid' : ''}>
                                                    {/*<input*/}
                                                    {/*placeholder={'Ex. XYZ-CO-301-1000: ePRO Proposal 1.0 w/ EDC Integration (EU/US)'}*/}
                                                    {/*onChange={(e) => {*/}
                                                    {/*this.isValid()*/}
                                                    {/*this.setState({name: e.target.value})*/}
                                                    {/*}}*/}
                                                    {/*className='margin_top_md w100' type={'text'} name={'name'}/>*/}
                                                    <Input
                                                        type={'text'}
                                                        placeholder={'Ex. XYZ-CO-301-1000: ePRO Deployment (EU/US)'}
                                                        label={''}
                                                        className={'w100 margin_top_md'}
                                                        name={'name'}
                                                        value={this.props.store.name || `${new Date().toLocaleDateString('en-US')} Deployment Request`}
                                                        onChange={(e) => {
                                                            this.isValid()
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'w100 margin_top_lg'}>

                                            <div>
                                                <h4 className={'margin_top_md'}>Type</h4>
                                                <div className={'section'}>
                                                    <Radio name="proposal_type" value="onetime-sdc" text={(
                                                        <span>One Time Playbook (survey)</span>)}
                                                           onChange={this.changeProposalType}
                                                    />
                                                    <Radio name="proposal_type" value="sdc" text={(
                                                        <span>e-Diary / ePRO (ongoing data collection)</span>)}
                                                           onChange={this.changeProposalType}
                                                    />
                                                    {/*<Radio name="proposal_type" value="epro" text={(*/}
                                                    {/*<span>(<b>ePRO</b>) Electronic Patient Reported Outcome (patient take home)</span>)}*/}
                                                    {/*onChange={this.changeProposalType}*/}
                                                    {/*/>*/}
                                                    {/*<Radio name="proposal_type" value="ecoa" text={(*/}
                                                    {/*<span>(<b>eCOA</b>) Electronic Clinical Outcome Assessment (in-clinic)</span>)}*/}
                                                    {/*onChange={this.changeProposalType}*/}
                                                    {/*/>*/}
                                                </div>
                                            </div>

                                            <div>
                                                {/*<h4 className={'margin_top_lg'}>Proposal Labelling</h4>*/}
                                                {/*<div className={'section'}>*/}
                                                {/*<div>*/}
                                                {/*<label>*/}
                                                {/*<b>Organization Name</b>*/}
                                                {/*<div className={'margin_top_sm'}>*/}
                                                <Input
                                                    hidden
                                                    type={'text'}
                                                    label={''}
                                                    name={'organization_title'}
                                                    value={this.props.store.organization_title || ''}
                                                />
                                                <input hidden name={'organization_id'}
                                                       defaultValue={this.props.store.organization.id}/>
                                                {/*</div>*/}
                                                {/*</label>*/}
                                                {/*</div>*/}
                                                {/*<div>*/}
                                                {/*<label>*/}
                                                {/*<b>Organization Logo</b>*/}
                                                {/*<div>*/}
                                                {/*<input className='margin_top_md' type={'file'}*/}
                                                {/*name={'proposal_logo_file'}/>*/}
                                                {/*</div>*/}
                                                {/*</label>*/}
                                                {/*</div>*/}
                                                {/*</div>*/}
                                                <div>
                                                    <h4 className={'margin_top_lg'}>Zone Count</h4>
                                                    <div className='section'>
                                                        <Radio name="zone_count" value="1"
                                                               text={(<span><b>1 Zone</b> (US, EU or other)</span>)}/>
                                                        <Radio name="zone_count" value="2"
                                                               text={(<span><b>2 Zones</b> (US & EU or other)<span
                                                                   className={'optional-surcharge'}>*Optional surcharge</span></span>)}/>
                                                        <Radio name="zone_count" value="3" text={(
                                                            <span><b>3 Zones</b> (US & EU & other)<span
                                                                className={'optional-surcharge'}>*Optional surcharge</span></span>)}/>
                                                    </div>

                                                </div>
                                                <div>
                                                    <h4 className={'margin_top_lg'}>

                                                        Internationalization
                                                    </h4>
                                                    <div className={'section'}>
                                                        <Input
                                                            type={'number'}
                                                            label={'Number of Languages'}
                                                            name={'language_count'}
                                                            value={this.props.store.language_count || 1}
                                                        />
                                                    </div>
                                                </div>

                                            </div>


                                        </div>

                                        {
                                            !this.props.store.proposal_type.includes('sdc') &&
                                            <div>
                                                <h4 className={'margin_top_lg'}>Integration Count</h4>
                                                <div className={'section'}>
                                                    <Radio name="integration_count" value="1"
                                                           text={(
                                                               <span><b>1</b> integration (First is free: EDC, Supply Chain, etc.)</span>)}/>
                                                    <Radio name="integration_count" value="2"
                                                           text={(<span><b>2</b> integrations</span>)}/>
                                                    <Radio name="integration_count" value="2+"
                                                           text={(<span>Call (<b>+2</b> integrations)</span>)}/>
                                                </div>
                                            </div>
                                        }

                                        {
                                            !this.props.store.proposal_type.includes('sdc') &&
                                            <div>
                                                <h4 className={'margin_top_lg'}>Coordination Hours</h4>
                                                <div className={'section'}>
                                                    <Radio name="coordination_hours" value="0-20" text={(
                                                        <span><b>0-20</b> (Standard) Data-Ops Coordination Hours</span>)}/>
                                                    <Radio name="coordination_hours" value="21-50" text={(
                                                        <span><b>21-50</b> (Premium) Data-Ops Coordination Hours</span>)}/>
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div className="w50-proposal-right">
                                        {
                                            this.props.store.proposal_type.includes('sdc') &&
                                            <div>
                                                <h4 className={'margin_top_lg'}>Patient Count</h4>
                                                <div className={'section'}>
                                                    <Radio name="patient_count" value="0-25"
                                                           text={(<span><b>0-25</b> patients</span>)}/>
                                                    <Radio name="patient_count" value="26-50"
                                                           text={(<span><b>26-50</b> patients</span>)}/>
                                                    <Radio name="patient_count" value="51-200"
                                                           text={(<span><b>51-200</b> patients</span>)}/>
                                                    <Radio name="patient_count" value="201-1000"
                                                           text={(<span><b>201-1,000</b> patients</span>)}/>
                                                    <Radio name="patient_count" value="1001-5000"
                                                           text={(<span><b>1,001-5,000</b> patients<span
                                                               className={'optional-surcharge'}>*Optional surcharge</span></span>)}/>
                                                    <Radio name="patient_count" value="5000+"
                                                           text={(<span><b>5,000+</b> patients<span
                                                               className={'optional-surcharge'}>*Optional surcharge</span></span>)}/>
                                                </div>
                                            </div>
                                        }

                                        {
                                            !this.props.store.proposal_type.includes('sdc') &&
                                            <div>
                                                <h4 className={'margin_top_lg'}>Site Count</h4>
                                                <div className={'section'}>
                                                    <Radio name="site_count" value="0-15"
                                                           text={(<span><b>0-15</b> sites</span>)}/>
                                                    <Radio name="site_count" value="16-30"
                                                           text={(<span><b>16-30</b> sites</span>)}/>
                                                    <Radio name="site_count" value="31-70"
                                                           text={(<span><b>31-70</b> sites</span>)}/>
                                                    <Radio name="site_count" value="71-120"
                                                           text={(<span><b>71-120</b> sites</span>)}/>
                                                </div>
                                            </div>
                                        }



                                        <div className={''}>


                                            {
                                                !this.props.store.proposal_type.includes('sdc') &&
                                                <div>
                                                    <h4 className={'margin_top_lg'}>Support Hours</h4>
                                                    <div className={'section'}>
                                                        <Radio name="support_hrs" value="standard"
                                                               text={(
                                                                   <span><b>Standard US Business Hrs</b> (9-5pm MST, Mon–Fri)</span>)}/>
                                                        <Radio name="support_hrs" value="extended"
                                                               text={(
                                                                   <span><b>Extended US Business Hrs</b> (7-9pm MST, Mon–Fri)</span>)}/>
                                                        <Radio name="support_hrs" value="all-hours-business-days"
                                                               text={(
                                                                   <span><b>All Hrs Business Days</b> (5-11pm MST, Mon–Fri)</span>)}/>
                                                        <Radio name="support_hrs" value="all-hours"
                                                               text={(<span><b>24/7 Hrs</b> (Sun-Sat)</span>)}/>
                                                    </div>
                                                </div>
                                            }

                                            {
                                                !this.props.store.proposal_type.includes('sdc') &&
                                                <div>
                                                    <h4 className={'margin_top_lg'}>
                                                        Duration (in Months)
                                                    </h4>

                                                    <div className={'section'}>
                                                        <Input
                                                            type={'number'}
                                                            label={'Duration of Support Contract'}
                                                            name={'duration'}
                                                            value={this.props.store.duration}
                                                            onChange={(value) => {
                                                                this.props.updateField('duration', Math.min(value, 28))
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            }

                                            {
                                                !this.props.store.proposal_type.includes('sdc') &&
                                                <div>
                                                    <h4 className={'margin_top_lg'}>Training</h4>
                                                    <div className={'section'}>
                                                        <Radio name='training' value="standard" text={(
                                                            <span>(<b>Standard</b>) Digital Materials Only</span>)}/>
                                                        <Radio name='training' value="3-virtual" text={(
                                                            <span>(<b>3 Virtual</b>) Training Sessions</span>)}/>
                                                        <Radio name='training' value="individualized-site" text={(
                                                            <span>(<b>Individualized Site Training</b>) Virtually-Lead + Site Checkoff</span>)}/>
                                                    </div>
                                                </div>
                                            }
                                            {/*<CustomLineItems*/}

                                            {/*/>*/}
                                            <div>
                                                <h4 className={'margin_top_lg'}>Deployment Details</h4>
                                                <div className={'section'}>
                                                    Deployment notes / questions:
                                                   <textarea
                                                        label={'Placeholder'}
                                                        field={'placeholder'}
                                                        className={'title-input-box'}
                                                        name={'deployment_note'}
                                                    />
                                                    <div className={'margin_top_md'}>
                                                        Requested deployment date
                                                        <input
                                                            type={'date'}
                                                            className={'margin_left_md'}
                                                            name={'req_deployment_date'}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={'section'}>
                                                    <div className={'margin_top_md'}>
                                                        Contact Name
                                                        <input
                                                            type={'text'}
                                                            className={'w100'}
                                                            required
                                                            minLength={3}
                                                            maxLength={55}
                                                            name={'contact_name'}
                                                        />
                                                    </div>
                                                    <div className={'margin_top_md'}>
                                                        Contact Phone
                                                        <input
                                                            type={'text'}
                                                            className={'w100'}
                                                            required
                                                            minLength={10}
                                                            maxLength={15}
                                                            name={'contact_phone'}
                                                        />
                                                    </div>
                                                    <div className={'margin_top_md margin_bottom_lg'}>
                                                        Contact Email
                                                        <input
                                                            type={'email'}
                                                            className={'w100'}
                                                            name={'contact_email'}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={'col-lg-2 col-sm-12'}></div>
                        </div>
                    </div>


                    <div>
                        <Summary
                            onSubmit={() => {
                                this.submit()
                            }}
                        />
                        <div className={'row no-margin'}>
                            <div className="margin_bottom_xxl"></div>
                        </div>
                    </div>
                </form>
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        store: state
    };
}

export default connect(mapStateToProps, {
    updateField,
    patch,
})(Body)
