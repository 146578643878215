import React, {Component} from 'react'
import StopTypingInput from "../shared/StopTypingInput";

class AuditListing extends Component {

    constructor(props) {
        super(props)
        this.state = {
            audit: props.audit,
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.audit.id !== this.props.audit.id) {
            this.setState({audit: this.props.audit})
        }
    }

    render() {
        const {audit} = this.state
        return (
            <div className="audit-listing no-select" key={this.props.index}>
                <div className="w100" onClick={() => this.setState({expanded: !this.state.expanded})}>
                    <div>{audit.name}</div>
                    {
                        this.state.expanded &&
                        <div>
                            {JSON.stringify(this.props.audit)}
                        </div>
                    }

                </div>
            </div>
        )
    }

}

export default AuditListing
