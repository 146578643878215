import React, {Component} from 'react'
import {connect} from 'react-redux';
import {addRun, removeRun, touchRuns, updateRun} from './redux/actions'
import UserNav from "../../UserNav";
import {createRun} from "../../services/runs";
import RunsList from "./components/RunsList";
import RunsListWithStatus from "./components/RunsListWithStatus";

class Body extends Component {

    constructor(props) {
        super(props)
        this.addBlankRun = this.addBlankRun.bind(this)
        this.state = {
            search: '',
            playbookId: -1,
            byType: -1,
        }
    }

    async addBlankRun() {
        const blankRun = {name: 'New Run', playbook_id: this.state.playbookId}
        const resp = await createRun(this.props, blankRun)
        this.props.addRun(resp.run)
    }

    render() {
        const {category} = this.props.store
        const byType = this.state.byType
        return (
            <div className='whole-body category-body run-list'>
                <UserNav/>

                <div>
                    <div className={'row no-margin'}>
                        <img src={window.view_data.imgs.nav_banner_sm} className={'nav-banner'}/>
                        <div className={'col-lg-2'}></div>
                        <div className={'col-lg-8 margin_top_md'}>

                            {
                                this.props.store.runsFor &&
                                <div>
                                    <small>
                                        <a href={`/runs`}>All Runs</a> >
                                        <a href={`/runs_for/${this.props.store.playbooks[0].id}`}>{this.props.store.playbooks[0].name}</a>
                                    </small>
                                    <h3 className={'margin_left_xs'}>
                                        {this.props.store.playbooks[0].name}
                                    </h3>
                                </div>
                            }
                            {
                                !this.props.store.runsFor &&
                                <div className={'margin_top_sm margin_bottom_sm w100 text-left bread-crumb-js'}>
                                    <select
                                        className={'playbook-select-filter'}
                                        value={byType}
                                        onChange={(e) => {
                                            this.setState({byType: e.target.value})
                                        }}
                                    >
                                        <option value={-1}>All Playbooks</option>

                                        {
                                            this.props.store.playbooks.map((a,i) => {
                                                return (
                                                    <option value={a.id} key={`playbook-selection-${i}`}>{a.name}</option>
                                                )

                                            })
                                        }
                                    </select>
                                    {
                                        byType > -1 &&
                                        <div className="see-status">
                                            <a href={`/runs_for/${byType}`}>See Status By Playbook</a>
                                        </div>
                                    }
                                </div>
                            }
                            <hr/>


                            {
                                !this.props.store.archived &&
                                <div className={'w100'}>
                                    <small><a href={`/runs/archived`}
                                              className={'float-right'}>Archived</a></small>
                                </div>
                            }
                            {
                                this.props.store.archived &&
                                <div className={'w100'}>
                                    <small><a href={`/runs`}
                                              className={'float-right'}>Current</a></small>
                                </div>
                            }

                            <input
                                type={'text'}
                                placeholder={'Search run titles'}
                                className={'w100 margin_top_md'}
                                onChange={(e) => {
                                    this.setState({search: e.target.value})
                                }}

                            />
                            {/*<div className={'toolbar-utilities'}>*/}
                                {/*<div className={'toolbar-space'}></div>*/}
                                {/*<div className={'weight-display'}>12</div>*/}

                            {/*</div>*/}
                            {
                                this.props.store.runsFor &&
                                <RunsListWithStatus
                                    search={this.state.search}
                                    byType={this.state.byType}
                                />
                            }
                            {
                                !this.props.store.runsFor &&
                                <RunsList
                                    search={this.state.search}
                                    byType={this.state.byType}
                                />
                            }
                            <div className="margin_bottom_xl">
                                <div className="w100 text-right">
                                    <div className="margin_top_xl">
                                        <select className={'playbook-select'} onChange={(e)=>{ this.setState( { playbookId: e.target.value})}}>
                                            <option value={-1}>Select Playbook</option>

                                            {
                                                this.props.store.playbooks.map((a,i)=>{
                                                    return(
                                                        <option value={a.id} key={`playbook-new-select-${i}`}>{a.name}</option>
                                                    )

                                                })
                                            }
                                        </select>
                                    </div>

                                    <div className={`run-button ${this.enableDisableClass}`} onClick={this.addBlankRun}>
                                        (+) New Run
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'col-lg-2'}></div>
                        <div className="margin_top_xxl"></div>
                    </div>
                </div>
            </div>
        )
    }

    get enableDisableClass(){
        return (this.disableNewRunButton) ? 'disabled' : ''
    }

    get disableNewRunButton(){
        return this.state.playbookId==-1
    }

}

function mapStateToProps(state) {
    return {
        store: state
    };
}

export default connect(mapStateToProps, {
    addRun,
    removeRun,
    touchRuns,
    updateRun
})(Body)
