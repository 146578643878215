import React from 'react';
import {Provider, connect} from 'react-redux';
import {createStore} from 'redux';
import rootReducer from './Proposals/redux/reducer';
import Body from './Proposals/Body';
import { getConfig } from "../services/proposal";
import { defaults } from "../utils/proposalConfig";

const csfrToken = document.getElementsByName('csrf-token')[0];
const authenticationToken = csfrToken != null ? csfrToken.getAttribute('content') : '';

function mapStateToProps(state) {
    return {
        store: state
    }
}


const ConnectedBody = connect(mapStateToProps, {})(Body);

class Proposals extends React.Component {

    constructor(props){
        super(props)
        const config = getConfig()
        const initial_state = {
            authenticationToken: authenticationToken,
            hi: 0,
            category: props.category,
            categories: props.categories || [],
            playbook: props.playbook,
            organization: props.organization || {},
            organization_title: (props.organization || {}).name,
            playbooks: props.playbooks || [],
            ...defaults(config, 'sdc'),
            config,
            customLineItems: [],
            ...props.proposal,
        };
        this.store = createStore(rootReducer, initial_state);
    }

    render() {
        return (
            <Provider store={{...this.store}}>
                <ConnectedBody
                />
            </Provider>
        );
    }
}

export default Proposals
