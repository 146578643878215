import React, {Component} from 'react'
import {connect} from 'react-redux';
import {changeActiveQuestionValue} from "./Designer/redux/actions";

class AddableTextInput extends Component {

    constructor(props) {
        super(props)
        this.added = this.added.bind(this)
    }

    changeText(e) {
        this.props.changeActiveQuestionValue(this.props.field, e.target.value)
    }

    added(shouldAdd) {
        this.props.changeActiveQuestionValue(`${this.props.field}-added`, shouldAdd) 
    }

    render() {
        const added = this.props.store.activeQuestion[`${this.props.field}-added`]
        if (added) {
            return (
                <label className={'w100 bold-header'}>
                    {this.props.label}
                    <div className={'close-addable'} onClick={() => this.added(false)}>
                        <div className={'addable-minus'}><span>-</span></div>
                    </div>
                    <div className={'text-input-boxes'}>
                        {
                            this.props.textarea &&
                            <textarea
                                className={'w100'}
                                value={this.props.store.activeQuestion[this.props.field]}
                                onChange={this.changeText.bind(this)}
                            />
                        }
                        {
                            !this.props.textarea &&
                            <input
                                type={'text'}
                                className={'w100'}
                                value={this.props.store.activeQuestion[this.props.field]}
                                onChange={this.changeText.bind(this)}
                            />
                        }
                    </div>
                </label>
            )
        } else {
            return (
                <label className={'w100 bold-header'} onClick={() => this.added(true)}>
                    <div className={'addable-plus'}><span>+</span></div> {this.props.label}
                </label>
            )
        }
    }

}

function mapStateToProps(state) {
    return {
        store: state
    };
}

export default connect(mapStateToProps, {
    changeActiveQuestionValue
})(AddableTextInput)
