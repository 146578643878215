import React, {Component} from 'react'
import {connect} from 'react-redux';
import {addPlaybook, removePlaybook, touchPlaybooks, updatePlaybook} from '../redux/actions'
import {inactivatePlaybook, patchPlaybook, } from "../../../services/playbook";
import StopTypingInput from "../../../shared/StopTypingInput";
import globals from '../../../config/globals'
import ArchiveAndRestore from "./ArchiveAndRestore";

class Input extends Component {


    constructor(props) {
        super(props)
        this.updatePlaybook = this.updatePlaybook.bind(this)
        this.inactivatePlaybook = this.inactivatePlaybook.bind(this)
    }

    async updatePlaybook(id, text){
        const fields = { name: text }
        const resp = await patchPlaybook(this.props, id, fields)
        this.props.updatePlaybook(resp.playbook)
    }

    async inactivatePlaybook(id){
        const resp = await inactivatePlaybook(this.props, id)
        this.props.removePlaybook(resp.playbook)
    }

    render() {
        const {playbook, index} = this.props
        return (
            <div className={'cursor-pointer'} onClick={()=> window.location = globals.playbook_builder_navigation(playbook.id)}>
                <div className={`card text-left playbook-row no_select cursor-pointer padding-vertical-md ${playbook.selected ? 'selected' : ''}`} key={index}>
                    <ArchiveAndRestore
                        playbook={playbook}
                        authenticationToken={this.props.authenticationToken}
                    />
                    <img src={window.view_data.imgs.access} className={'access-icon float-left'} />
                    <StopTypingInput
                        id={playbook.id}
                        onFocus={()=>this.props.updatePlaybook({ id: playbook.id })}
                        className={'w100 category-name'}
                        value={playbook.name}
                        timeForStop={400}
                        defaultValue={playbook.name}
                        onTextStop={(text)=>{
                            this.updatePlaybook(playbook.id, text)
                        }}
                    />

                    <div className={'launch-playbook-builder'} onClick={(e)=> {
                        e.stopPropagation()
                        window.location = globals.playbook_builder_navigation(playbook.id)
                    }}>
                        <img src={window.view_data.imgs.launch}  className={'launch-icon'} />
                        &nbsp;LAUNCH PLAYBOOK DESIGNER
                    </div>
                </div>
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        store: state
    };
}

export default connect(mapStateToProps, {
    addPlaybook,
    removePlaybook,
    touchPlaybooks,
    updatePlaybook
})(Input)
