import React, {Component} from 'react'
import {connect} from 'react-redux';
import {changeActiveQuestionValue} from "./redux/actions";

class Select extends Component {

    change(e) {
        this.props.changeActiveQuestionValue(this.props.field, this.value(e))
    }

    value(e) {
        if (this.props.boolean) {
            return e.target.value === 'true'
        } else {
            return e.target.value
        }
    }

    render() {
        return (
            <label className={'w100 bold-header'}>
                {this.props.label}
                <div>
                    <select
                        onChange={this.change.bind(this)}
                        value={`${this.props.store.activeQuestion[this.props.field]}`}
                        className={'w100 select'}
                    >
                        {
                            this.props.options.map((option, index) => {
                                return (
                                    <option value={option.value} key={index}>{option.displayText}</option>
                                )
                            })
                        }
                    </select>
                </div>
            </label>
        )
    }

}

function mapStateToProps(state) {
    return {
        store: state
    };
}

export default connect(mapStateToProps, {
    changeActiveQuestionValue
})(Select)