import React, {Component} from 'react'
import {connect} from 'react-redux';
import {updateField,} from '../redux/actions'

class Input extends Component {

    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div>
                {
                    this.props.label &&
                    <div>
                        <label>
                            {this.props.label}
                        </label>
                    </div>
                }
                <input
                    {...this.props}
                    className={this.props.className || 'margin_right_md'}
                    type={this.props.type}
                    name={this.props.name}
                    value={this.props.value}
                    onChange={(e) => {
                        this.props.updateField(this.props.name, e.target.value)
                        this.props.onChange ? this.props.onChange(e.target.value) : null
                    }}
                />
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        store: state
    };
}

export default connect(mapStateToProps, {
    updateField,
})(Input)
