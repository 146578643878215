import React, {Component} from 'react'
import {patchProposal} from "../../services/proposal";

class ArchiveAndRestoreButton extends Component {

    render() {
        return (
            <div className="archive" onClick={(e) => {
                e.stopPropagation()
                const newProposal = {
                    ...this.props.proposal,
                    status: this.props.proposal.status === 'NEW' ? 'ARCHIVED' : 'NEW',
                }

                patchProposal(newProposal, this.props)

                this.props.onChange(newProposal)
            }}>{this.props.proposal.status === 'NEW' ? 'Archive' : 'Restore' }</div>
        )
    }

}

export default ArchiveAndRestoreButton
