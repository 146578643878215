import React, {Component} from 'react'
import {connect} from 'react-redux';

class NumericRating extends Component {


    get numberArray() {
        const answers = this.props.store.activeQuestion.answers
        if (answers[0]) {
            let arr = []

            for (let i = answers[0].min; i <= answers[0].max; i++) {
                arr.push(i)
            }

            return arr
        } else {
            return []
        }
    }


    render() {
        const answers = this.props.store.activeQuestion.answers
        const answer = answers[0]
        if(answer) {
            return (
                <div className={'w100 margin_top_sm padded_sm'}>
                    <div className="numeric-rating-container">
                        {
                            this.numberArray.map((num, i) => {
                                return (
                                    <div className={`numeric-key key-${num}`} key={i}>{num}</div>
                                )
                            })
                        }
                    </div>
                    <div className={'w100 position-relative'}>
                        <div className="numeric-range-left-div">
                            <div>|</div>
                            <div className="numeric-range-left-text">{answer.min_description}</div>
                        </div>
                        <div className="numeric-range-right-div">
                            <div>|</div>
                            <div className="numeric-range-right-text">{answer.max_description}</div>
                        </div>
                        <img src={window.view_data.imgs.numeric_rating} className={'numeric-rating-number-centered'}/>
                    </div>

                </div>
            )
        } else {
            return (<div></div>)
        }
    }

}

function mapStateToProps(state) {
    return {
        store: state
    };
}

export default connect(mapStateToProps, {})(NumericRating)
