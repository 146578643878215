import React, {Component} from 'react'

export default class StopTypingInput extends Component {

    constructor(props) {
        super(props)
        if(!props.id){ throw 'StopTypingInput requires a unique ID prop (this.props.id)' }
        this.changeText = this.changeText.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.blur = this.blur.bind(this);
        this._handleKeyDown = this._handleKeyDown.bind(this);
        this.state = {
            editing: false,
            text: '',
            typing: false,
            typingTimeout: 0
        }
    }

    blur(){
        this.nameInput.blur()
    }

    _handleKeyDown(e) {
        if (e.key === 'Enter') {
            this.blur()
        }
    }

    changeText = (event) => {
        const self = this;

        if (self.state.typingTimeout) {
            clearTimeout(self.state.typingTimeout);
        }

        self.setState({
            text: event.target.value,
            typing: false,
            typingTimeout: setTimeout(function () {
                self.props.onTextStop(self.state.text);
            }, self.props.timeForStop)
        });
    }


    onBlur(){
        this.setState({ editing: false })
    }

    render() {
        if(this.state.editing){
            return (
                <input
                    key={`${this.props.id}`} /* REQUIRED */
                    ref={(input) => { this.nameInput = input; }}
                    autofocus="true"
                    onBlur={ this.onBlur }
                    onKeyDown={this._handleKeyDown}
                    onClick={(e)=>{
                        e.stopPropagation()
                    }}
                    type={'text'}
                    defaultValue={this.props.defaultValue}
                    onChange={this.changeText}
                    className={`${this.props.className} stop-typing-input stop-input`}
                />
            )
        } else {
            return (
                <div className={`${this.props.className} stop-typing stop-input`} key={this.props.id} onClick={(e)=>{
                    e.stopPropagation()
                    let self = this;
                    this.props.onFocus ? this.props.onFocus() : null
                    this.setState({editing: true, editingMs: new Date().getTime() })
                    setTimeout(()=>{
                        self.nameInput.focus();
                        self.nameInput.select();
                    }, 50)
                }}>
                    {this.props.defaultValue}
                    {this.props.postText}
                </div>
            )
        }


    }

}
