import React, {Component} from 'react'
import {connect} from 'react-redux';
import {patchState} from "../redux/actions";
import prepStoreForSaving from "../../../utils/prepStoreForSaving";

class VersionControlType extends Component {



    render() {
        const {store} = this.props
        return (
            <div>
                <div className={'card-padding'}>
                    Version Control

                    <div className={'w100'}>
                        {
                            (this.props.store.design_states || [])
                                .sort((a,b) => a.id > b.id ? -1 : 1)
                                .map((ds)=>{
                                const date = new Date(Number(ds.createdAt))
                                    return (
                                        <div className={'version-name-pill'} onClick={()=>{
                                            this.props.patchState({
                                                ...JSON.parse(ds.store),
                                                no_edit: ds,
                                                current_design_state: {
                                                    ...store.current_design_state,
                                                    store: prepStoreForSaving(this.props.store),
                                                }
                                            })
                                        }}>
                                            {date.toLocaleDateString()} {date.toLocaleTimeString()}
                                            <div className={'restore-note'}>{ds.restore_note}</div>
                                        </div>
                                    )
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        store: state
    };
}

export default connect(mapStateToProps, {
    patchState,
})(VersionControlType)
