
export default (state = {}, action) => {
    let new_state = state;

    switch (action.type) {
        case 'add_playbook':
            new_state = {
                ...state,
                playbooks: [...removeSelection(state.playbooks), { ...action.playbook, selected: true }]
            }
            break;
        case 'remove_playbook':
            new_state = {
                ...state,
                playbooks: removeSelection(state.playbooks).filter((playbook)=> playbook.id !== action.playbook.id)
            }
            break;
        case 'update_playbook':
            new_state = {
                ...state,
                playbooks: removeSelection(state.playbooks).map((playbook)=> {
                    if(playbook.id === action.playbook.id){
                        return {
                            ...playbook,
                            ...action.playbook,
                            selected: true
                        }
                    } else {
                        return playbook
                    }
                })
            }
            break;
        case 'touch_playbooks':
            new_state = {
                ...state,
                playbooks: removeSelection(state.playbooks)
            }
            break;
    }


    return new_state

}

const removeSelection = (playbooks)=>{
    return playbooks.map((a)=>({...a, selected: false}))
}
