import React, {Component} from 'react'

class ActionButton extends Component {

    render() {
        return (
            <div className="action-button" onClick={(e) => {
                e.stopPropagation()
                window.location = this.props.href
            }}>{this.props.text}</div>
        )
    }

}

export default ActionButton
