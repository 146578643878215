
export default (state = {}, action) => {
    let new_state = state;

    switch (action.type) {
        case 'add_run':
            new_state = {
                ...state,
                runs: [...removeSelection(state.runs), { ...action.run, selected: true }]
            }
            break;
        case 'remove_run':
            new_state = {
                ...state,
                runs: removeSelection(state.runs).filter((run)=> run.id !== action.run.id)
            }
            break;
        case 'update_run':
            new_state = {
                ...state,
                runs: removeSelection(state.runs).map((run)=> {
                    if(run.id === action.run.id){
                        return {
                            ...run,
                            ...action.run,
                            selected: true
                        }
                    } else {
                        return run
                    }
                })
            }
            break;
        case 'touch_runs':
            new_state = {
                ...state,
                runs: removeSelection(state.runs)
            }
            break;
    }


    return new_state

}

const removeSelection = (runs)=>{
    return runs.map((a)=>({...a, selected: false}))
}
