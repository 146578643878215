import React, {Component} from 'react'
import {connect} from 'react-redux';
import {removePlaybook} from '../redux/actions'
import {inactivatePlaybook, activatePlaybook} from "../../../services/playbook";

class Body extends Component {


    async activatePlaybook(id) {
        const resp = await activatePlaybook(this.props, id)
        this.props.removePlaybook(resp.playbook)
    }

    async inactivatePlaybook(id) {
        const resp = await inactivatePlaybook(this.props, id)
        this.props.removePlaybook(resp.playbook)
    }

    render() {
        const { playbook } = this.props
        if(playbook.status === 'NEW'){
            return (
                <div className={'restore-playbook'} onClick={(e) => {
                    e.stopPropagation();
                    console.log(playbook)
                    this.inactivatePlaybook(playbook.id)
                }}>
                    {'Archive'}
                </div>
            )
        } else {
            return (
                <div className={'restore-playbook'} onClick={(e) => {
                    e.stopPropagation();
                    console.log(playbook)
                    this.activatePlaybook(playbook.id)
                }}>
                    {'Restore'}
                </div>
            )
        }

    }

}

function mapStateToProps(state) {
    return {
        store: state
    };
}

export default connect(mapStateToProps, {
    removePlaybook
})(Body)
