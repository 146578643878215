import React from 'react';
import {Provider, connect} from 'react-redux';
import {createStore} from 'redux';
import rootReducer from './Run/redux/reducer';
import Body from './Run/Body';

const csfrToken = document.getElementsByName('csrf-token')[0];
const authenticationToken = csfrToken != null ? csfrToken.getAttribute('content') : '';

function mapStateToProps(state) {
    return {
        store: {
            ...state,
        },
        authenticationToken: authenticationToken
    }
}

const ConnectedBody = connect(mapStateToProps, {})(Body);

class Designer extends React.Component {

    constructor(props){
        super(props)
        const initial_state = {
            authenticationToken: authenticationToken,
            hi: 0,
            origin: props.origin,
            playbook: props.playbook,
            run: props.run,
            runs: (props.runs || [])
                .sort((a, b) => a.name.localeCompare(b.name)),
            questions: this.questions(props),

        };
        this.store = createStore(rootReducer, initial_state);
    }

    questions(props){
        const { questions } = JSON.parse(props.current_design_state.store)
        return questions
            .filter((q)=>q.status==='NEW')
            .map((q)=>{
                return {
                    ...q,
                    runState: props.run_state.filter((s)=>(s.question_id == q.id)) || []
                }
        })
    }

    render() {
        return (
            <Provider store={{...this.store}}>
                <ConnectedBody
                />
            </Provider>
        );
    }
}

export default Designer
