import React, {Component} from 'react'
import {connect} from 'react-redux';
import {patchState} from "../redux/actions";
import {restoreVersion} from "../../../services/designer";


class VersionControlAlertBar extends Component {

    noEditMessage(ds) {
        const date = new Date(Number(ds.createdAt))
        return `You are viewing: ${date.toLocaleDateString()} ${date.toLocaleTimeString()}, no adjustments made until you `
    }

    render() {
        const {store} = this.props
        if (store.no_edit) {
            return (
                <div className={'alert-bar'}>
                    {this.noEditMessage(store.no_edit)}
                    <span
                        className={'version-control-msg-link'}
                        onClick={async () => {
                            try {
                                const resp = await restoreVersion(store, store.no_edit.id)
                                if(resp){
                                    this.props.patchState({no_edit: false, ...JSON.parse(store.no_edit.store)})
                                }
                            } catch(err){
                                console.log(err)
                                window.alert(`Restore failed, please contact support. Error: ${err.toString()}`)
                            }
                        }}
                    >restore</span> this version or&nbsp;
                    <span
                        className={'version-control-msg-link'}
                        onClick={() => {
                                const returnStore = JSON.parse(store.current_design_state.store)
                                this.props.patchState({
                                    ...returnStore,
                                    no_edit: false,
                                })

                        }}
                    >close version control</span>
                </div>)
        } else {
            return (<div></div>)
        }


    }

}

function mapStateToProps(state) {
    return {
        store: state
    };
}

export default connect(mapStateToProps, {
    patchState,
})(VersionControlAlertBar)
