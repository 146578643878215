
export default (state = {}, action) => {
    let new_state = state;

    switch (action.type) {
        case 'update_field':
            new_state = {
                ...state,
                [action.field]: action.value
            }
            break;
        case 'patch':
            new_state = {
                ...state,
                ...action.state
            }
            break;
    }


    return new_state

}
