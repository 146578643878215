import React from 'react';
import {Provider, connect} from 'react-redux';
import {createStore} from 'redux';
import rootReducer from './Portal/redux/reducer';
import Body from './Portal/Body';

const csfrToken = document.getElementsByName('csrf-token')[0];
const authenticationToken = csfrToken != null ? csfrToken.getAttribute('content') : '';

function mapStateToProps(state) {
    return {
        store: state,
        authenticationToken: authenticationToken
    }
}

const ConnectedBody = connect(mapStateToProps, {})(Body);

class Designer extends React.Component {

    constructor(props){
        super(props)
        const initial_state = {
            hi: 0,
            archived: props.archived,
            categories: (props.categories || [])
                .sort((a, b) => a.name.localeCompare(b.name))
        };
        this.store = createStore(rootReducer, initial_state);
    }

    render() {
        return (
            <Provider store={{...this.store}}>
                <ConnectedBody
                />
            </Provider>
        );
    }
}

export default Designer
