export default class ProposalHelper {

    static sum(store) {
        const {config} = store

        const duration = Number(store.duration)
        const typeConfig = config[store.proposal_type]

        const baseCost = typeConfig.baseCost
        const zone_count = typeConfig.zone_count[store.zone_count]
        const patient_count = typeConfig.patient_count[store.patient_count] || 0
        const language_count = this.counter(typeConfig, store, 'language_count')
        const site_count = typeConfig.site_count[store.site_count] || 0
        const training = typeConfig.training[store.training]
        const integration_count = typeConfig.integration_count[store.integration_count]
        const coordination_hours = typeConfig.coordination_hours[store.coordination_hours]
        const support_hrs = typeConfig.support_hrs[store.support_hrs]

        console.log(baseCost,
            zone_count,
            patient_count,
            language_count,
            site_count,
            training,
            integration_count,
            coordination_hours,
            support_hrs * duration)

        return baseCost +
            zone_count +
            patient_count +
            language_count +
            site_count +
            training +
            integration_count +
            coordination_hours +
            support_hrs * duration
    }

    static totalDT(store){
        return this.sum(store)
    }

    static sumWithMarkup(store){
        return this.sum(store) * (1 + (store.config[store.proposal_type].markupPercent / 100))
    }

    static grandTotal(store){
        return this.sumWithMarkup(store)
    }

    static resellerMargin(store){
        return this.sumWithMarkup(store) - this.sum(store)
    }

    static monthlyPayment(store){
        const sumWithMarkup = this.sumWithMarkup(store)
        return sumWithMarkup / Number(store.duration)
    }

    static monthlyResellerMargin(store){
        const margin = this.resellerMargin(store)
        return margin / Number(store.duration)
    }

    static monthToMonthPayment(store){
        const sumWithMarkup = this.sumWithMarkup({ ...store, duration: 30 })
        return (sumWithMarkup / 30) * 1.12
    }

    static monthToMonthResellerMargin(store){
        const sum = this.sum({ ...store, duration: 30 })
        const sumWithMarkup = this.sumWithMarkup({ ...store, duration: 30 })
        return ((sumWithMarkup / 30) * 1.12) - ((sum / 30) * 1.12)
    }

    static counter(typeConfig, store, name){
        const count = Number(store[name])
        return count <= 1 ? typeConfig[name]['1'] : typeConfig[name].per * (count - 1)
    }
}
