

export const click = ()=>({type: 'click'})
export const patchState = (newState)=>({type: 'patch_state', newState })
export const setQuestionOrder = (questions)=>({type: 'set_question_order', questions })
export const setActiveQuestion = (question)=>({type: 'set_active_question', question })
export const setQuestion = (questionId, field, value)=>({type: 'set_question', questionId, field, value })
export const setActiveQuestionNext = (removedQuestion, statusMessage)=>({type: 'set_active_question_next', removedQuestion, statusMessage })
export const changeActiveQuestionValue = (key, value)=>({type: 'change_active_question_value', key, value })
export const updateAnswer = (answer)=>({type: 'update_answer', answer })
export const setProperties = (propertyObj)=>({type: 'set_properties', propertyObj })
export const setFiltered = (filtered)=>({type: 'set_filtered', filtered })
export const addNewQuestion = ()=>({type: 'add_new_question' })
