export default ( store )=>{
    let newStore = {
        ...store
    }
    delete newStore.authenticationToken  //important
    delete newStore.design_states //important
    delete newStore.current_design_state //important

    return JSON.stringify(newStore)
}
