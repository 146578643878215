
export default (state = {}, action) => {
    let new_state = state;

    switch (action.type) {
        case 'add_category':
            new_state = {
                ...state,
                categories: [...removeSelection(state.categories), {...action.category, selected: true}]
            }
            break;
        case 'remove_category':
            new_state = {
                ...state,
                categories: removeSelection(state.categories).filter((category)=> category.id !== action.category.id)
            }
            break;
        case 'update_category':
            new_state = {
                ...state,
                categories: removeSelection(state.categories).map((category)=> {
                 if(category.id === action.category.id){
                     return {
                         ...category,
                         ...action.category,
                         selected: true
                     }
                 } else {
                   return category
                 }
                })
            }
            break;
        case 'touch_categories':
            new_state = {
                ...state,
                categories: removeSelection(state.categories)
            }
            break;
    }

    return new_state

}

const removeSelection = (categories)=>{
  return categories.map((s)=>({...s, selected: false}))
}
