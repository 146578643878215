import React, {Component} from 'react'
import {connect} from "react-redux";
import {setQuestion} from "../Designer/redux/actions";
import Question from "./Question";

class WebHooker extends Component {

    constructor(props) {
        super(props)
        this.state = {}
        this.addQuestion = this.addQuestion.bind(this)
        this.toggleExpand = this.toggleExpand.bind(this)
    }

    addQuestion(){
        const {item} = this.props
        const questions = this.props.item.questions || []
        this.props.setQuestion(item.id, 'questions', [...questions, { id: new Date().getTime(), type: 'text', name: '', question: '' }])
    }

    toggleExpand(){
        const {expanded} = this.state
        this.setState({expanded: !expanded})
    }

    render() {

        const {item, index, store} = this.props
        const {expanded} = this.state
        const questions = (item.questions||[])
        return (
            <div className="display-box">
                <div className="section-expander" onClick={this.toggleExpand}>
                    Questions <div className={'expand-cta'}>{`${expanded ? 'close (x)' : '(edit)️'}`}</div>
                </div>
                {
                    !expanded &&
                    <div onClick={this.toggleExpand}>
                        {
                            questions.map((q, i)=>{
                                return (
                                    <div className={'read-only-webhooks'} key={`question-detail-${i}`}>
                                        {q.question} <span className="margin_left_xs">[{q.name}, {q.type}]</span>
                                    </div>
                                )
                            })
                        }
                    </div>
                }
                {
                    expanded &&
                        <div>

                            {
                                questions.map((question, i)=>{
                                    return (
                                        <Question
                                            item={item}
                                            question={question}
                                            i={i}
                                        />
                                    )
                                })
                            }

                            <div className="w100 text-right margin_top_md">
                                <div className='add-question inline-block' onClick={this.addQuestion}>
                                    Add Question
                                </div>
                            </div>
                        </div>
                }
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        store: state
    };
}

export default connect(mapStateToProps, {
    setQuestion,
})(WebHooker)
