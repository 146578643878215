import React, {Component} from 'react'
import {connect} from 'react-redux';

class OpenEnded extends Component {


    get numberArray(){
        const answers = this.props.store.activeQuestion.answers
        if(answers[0]){
            let arr = []

            for(let i = answers[0].min; i <= answers[0].max; i++){
                arr.push(i)
            }

            return arr
        } else {
            return []
        }
    }


    render() {

        return (
            <div className={'w100 margin_top_sm padded_sm'}>
               <div className={'device-text-input'}>
                   {this.props.store.activeQuestion.placeholder}
               </div>
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        store: state
    };
}

export default connect(mapStateToProps, {})(OpenEnded)
