import React, {Component} from 'react'
import {connect} from 'react-redux';
import {addRespondent, patchRespondent} from './redux/actions'
import UserNav from "../../UserNav";
import Doughnut from "./components/Doughnut";
import Collapser from "./components/Collapser";

class Body extends Component {

    constructor(props) {
        super(props)
        this.onAdd = this.onAdd.bind(this)
        this.identifierUnique = this.identifierUnique.bind(this)
        this.showing = this.showing.bind(this)
        this.state = {query: ''}
    }

    componentDidMount() {
        window.alert('Please note this is a sample deployment, and does not allow you to actually invite patients or download data. It is here for reference.')
    }

    onAdd() {
        const {identifier, email, sms} = this.state
        if (this.identifierUnique(identifier)) {
            this.props.addRespondent({
                identifier,
                sent: new Date().toLocaleDateString(),
                status: 'active',
                completionStatus: 'Sent',
            })
            this.setState({
                identifier: '',
                email: '',
                sms: '',
                message: '',
            })
        } else {
            this.setState({message: 'Identifier already in-use'})
        }
        this.identifier.focus()
    }

    identifierUnique(identifier) {
        return this.props.store.respondents.filter((r) => r.identifier === identifier).length === 0
    }

    suspended(respondent) {
        return respondent.status === 'suspended'
    }

    suspend(respondent) {
        this.props.patchRespondent(respondent.identifier, {
            status: this.suspended(respondent) ? 'active' : 'suspended'
        })
    }

    showing(identifier){
        return this.filter(this.props.store.respondents).filter((r)=> r.identifier === identifier).length > 0
    }

    render() {
        const {category, playbook, deployment} = this.props.store
        const filteredRespondents = this.filter(this.props.store.respondents)
        return (
            <div className='whole-body'>
                <UserNav/>
                <div>
                    <div className={'row no-margin'}>
                        <img src={window.view_data.imgs.nav_banner_sm} className={'nav-banner'}/>
                        <div className={'col-lg-2'}></div>
                        <div className={'col-lg-8 text-left margin_top_md'}>


                            <div className={'margin_top_sm margin_bottom_sm w100 text-left bread-crumb-js'}>
                                <a href={`/portal`}>Categories</a> > <a href={`/category/${category.id}`}>{category.name} –
                                Playbooks</a> > <a
                                href={`/playbook/${playbook.id}/deployment/${deployment.id}`}>{playbook.name} –
                                Deployment</a>
                            </div>
                            <hr/>

                            <div className={'row'}>
                                <div className={'col-xs-12 text-left margin_top_md text-center'}>
                                    <div className={'deployment-pie-row'}>
                                        <div className={'inline-block'}>
                                            <div className={'margin_bottom_sm'}>Today</div>
                                            <Doughnut
                                                respondents={filteredRespondents}
                                            />
                                        </div>
                                        <div className={'inline-block text-center download-icon-container'}>
                                            <div>
                                                <div>
                                                    <img src={window.view_data.imgs.download}
                                                         className={'icon'}/>
                                                </div>
                                                Export CSV
                                            </div>
                                            <div className={'margin_top_md'}>
                                                <div>
                                                    <img src={window.view_data.imgs.download}
                                                         className={'icon'}/>
                                                </div>
                                                Export SDTM
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className={'chart-container margin_bottom_xxl'}>
                                <table className={'table'}>
                                    <tr>
                                        <th></th>
                                        <th>Mon, 11/16</th>
                                        <th>Tues, 11/17</th>
                                        <th>Wed, 11/18</th>
                                        <th>Thurs, 11/19</th>
                                        <th>Fri, 11/20</th>
                                        <th>Sat, 11/21</th>
                                        <th>Sun, 11/22</th>
                                        <th>Mon, 11/23</th>
                                        <th>Tues, 11/24</th>
                                        <th>Wed, 11/25</th>
                                        <th>Thurs, 11/26</th>
                                        <th>Fri, 11/27</th>
                                        <th>Sat, 11/28</th>
                                        <th>Sun, 11/29</th>
                                        <th>Mon, 11/30</th>
                                        <th>Sun, 12/1</th>
                                    </tr>
                                    <tbody>
                                    {
                                        this.showing('3045001') &&
                                        <tr>
                                            <td>3045001</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td className={'grid-complete'}>1</td>
                                            <td className={'grid-complete'}>2</td>
                                            <td className={'grid-complete'}>3</td>
                                            <td className={'grid-incomplete'}>4</td>
                                            <td className={'grid-complete'}>5</td>
                                            <td className={'grid-complete'}>6</td>
                                            <td className={'grid-complete'}>7</td>
                                            <td className={'grid-complete'}>8</td>
                                            <td className={'grid-complete'}>9</td>
                                            <td className={'grid-complete'}>10</td>
                                            <td className={'grid-complete'}>11</td>
                                            <td className={'grid-complete'}>12</td>
                                            <td className={'grid-complete'}>13</td>
                                        </tr>
                                    }
                                    {
                                        this.showing('3045002') &&
                                        <tr>
                                            <td>3045002</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td className={'grid-complete'}>1</td>
                                            <td className={'grid-complete'}>2</td>
                                            <td className={'grid-complete'}>3</td>
                                            <td className={'grid-complete'}>4</td>
                                            <td className={'grid-complete'}>5</td>
                                            <td className={'grid-complete'}>6</td>
                                            <td className={'grid-abandoned'}>7</td>
                                            <td className={'grid-abandoned'}>8</td>
                                            <td className={'grid-complete'}>9</td>
                                            <td className={'grid-yet'}>10</td>
                                        </tr>
                                    }
                                    {
                                        this.showing('3045003') &&
                                        <tr>
                                            <td>3045003</td>
                                            <td></td>
                                            <td className={'grid-complete'}>1</td>
                                            <td className={'grid-incomplete'}>2</td>
                                            <td className={'grid-abandoned'}>3</td>
                                            <td className={'grid-complete'}>4</td>
                                            <td className={'grid-complete'}>5</td>
                                            <td className={'grid-complete'}>6</td>
                                            <td className={'grid-complete'}>7</td>
                                            <td className={'grid-complete'}>8</td>
                                            <td className={'grid-complete'}>9</td>
                                            <td className={'grid-complete'}>10</td>
                                            <td className={'grid-complete'}>11</td>
                                            <td className={'grid-complete'}>12</td>
                                            <td className={'grid-complete'}>13</td>
                                            <td className={'grid-complete'}>14</td>
                                            <td className={'grid-complete'}>15</td>
                                        </tr>
                                    }
                                    {
                                        this.showing('3045004') &&
                                        <tr>
                                            <td>3045004</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td className={'grid-complete'}>1</td>
                                            <td className={'grid-complete'}>2</td>
                                            <td className={'grid-complete'}>3</td>
                                            <td className={'grid-complete'}>4</td>
                                            <td className={'grid-complete'}>5</td>
                                            <td className={'grid-complete'}>6</td>
                                        </tr>
                                    }
                                    {
                                        this.showing('3045005') &&
                                        <tr>
                                            <td>3045005</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    }
                                    {
                                        this.showing('3045006') &&
                                        <tr>
                                            <td>3045006</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td className={'grid-complete'}>1</td>
                                            <td className={'grid-complete'}>2</td>
                                            <td className={'grid-complete'}>3</td>
                                            <td className={'grid-complete'}>4</td>
                                            <td className={'grid-complete'}>5</td>
                                            <td className={'grid-complete'}>6</td>
                                            <td className={'grid-complete'}>7</td>
                                            <td className={'grid-complete'}>8</td>
                                            <td className={'grid-abandoned'}>9</td>
                                        </tr>
                                    }
                                    {
                                        this.showing('3045007') &&
                                        <tr>
                                            <td>3045007</td>
                                            <td></td>
                                            <td></td>
                                            <td className={'grid-complete'}>1</td>
                                            <td className={'grid-complete'}>2</td>
                                            <td className={'grid-complete'}>3</td>
                                            <td className={'grid-complete'}>4</td>
                                            <td className={'grid-complete'}>5</td>
                                            <td className={'grid-complete'}>6</td>
                                            <td className={'grid-complete'}>7</td>
                                            <td className={'grid-complete'}>8</td>
                                            <td className={'grid-complete'}>9</td>
                                            <td className={'grid-complete'}>10</td>
                                            <td className={'grid-complete'}>11</td>
                                            <td className={'grid-complete'}>12</td>
                                            <td className={'grid-complete'}>13</td>
                                            <td className={'grid-yet'}>14</td>
                                        </tr>
                                    }
                                    {
                                        this.showing('3045008') &&
                                        <tr>
                                            <td>3045008</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    }
                                    </tbody>
                                </table>
                            </div>


                            <Collapser
                                label={'Add Respondent'}
                                iconColor={'#4858cd'}
                            >
                                <div className={'w100'}>
                                    <div className={'create-respondent'}>
                                        {
                                            this.state.message &&
                                            <div className={'danger-alert thin margin_left_xs'}>
                                                {this.state.message}
                                            </div>
                                        }
                                        <label className={'identifier'}>
                                            Identifier
                                            <input
                                                type={'text'}
                                                onChange={(e) => this.setState({identifier: e.target.value})}
                                                value={this.state.identifier}
                                                ref={(input) => {
                                                    this.identifier = input;
                                                }}
                                            />
                                        </label>
                                        <label className={'sms'}>
                                            <select className={'contact-method'}>
                                                <option>SMS</option>
                                                <option>Email</option>
                                            </select>
                                            <input
                                                type={'text'}
                                                onChange={(e) => this.setState({sms: e.target.value})}
                                                value={this.state.sms}
                                            />
                                        </label>
                                        <div className={'invite'}>
                                            <input type={'submit'} value={'Send'} onClick={this.onAdd}/>
                                        </div>
                                    </div>

                                </div>
                            </Collapser>
                            <div className={'w100'}>
                                <div className={'create-respondent'}>
                                    <input
                                        type={'text'}
                                        className={'w100'}
                                        onChange={(e) => this.setState({query: e.target.value})}
                                        placeholder={'Filter respondents by identifier'}
                                        value={this.state.query}
                                    />
                                </div>

                            </div>

                            <table className={'table margin_bottom_xxl'}>
                                <tr>
                                    <th className={'identifier text-left'}>Identifier</th>
                                    <th className={'sent text-center'}>Sent</th>
                                    <th className={'status text-right'}>Status</th>
                                    <th className={'actions text-right'}></th>
                                </tr>
                                <tbody>
                                {
                                    filteredRespondents.map((r) => {
                                        return (
                                            <tr className={`${r.status === 'active' ? 'active' : 'suspended'}`}>
                                                <td className={'identifier text-left'}>{r.identifier}</td>
                                                <td className={'sent text-center'}>{r.sent}</td>
                                                <td className={'status text-right'}>{r.completionStatus}</td>
                                                <td className={'actions text-right'}>
                                                    <input className={'action-btn alt'} type={'submit'}
                                                           value={'Re-invite'}/>
                                                    <input className={`action-btn ${this.suspended(r) ? 'alt2' : ''}`}
                                                           type={'submit'}
                                                           value={this.suspended(r) ? 'Activate' : 'Suspend'}
                                                           onClick={() => this.suspend(r)}/>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </table>


                        </div>
                        <div className={'col-lg-2'}></div>
                    </div>
                </div>
            </div>
        )
    }

    filter(arr) {
        const query = this.state.query.toLowerCase()
        if (query) {
            return arr.filter((item) => {
                return item.identifier.toLowerCase().includes(query) || item.status.toLowerCase().includes(query) || item.completionStatus.toLowerCase().includes(query)
            })
        } else {
            return arr
        }
    }

}

function mapStateToProps(state) {
    return {
        store: state
    };
}

export default connect(mapStateToProps, {
    addRespondent,
    patchRespondent,
})(Body)
