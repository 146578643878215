import React, { Component } from 'react'
import { connect} from 'react-redux';
import AnswerButtons from "./DeviceWorkArea/AnswerButtons";

class DeviceWorkarea extends Component {

    get body(){
        const isConfirmationScreen = this.props.store.activeQuestion.question_type === 'confirmation'
        const nextText = isConfirmationScreen ? 'COMPLETE' : 'NEXT →'
        const backText = '← BACK'
        return (
            <div className={'mobile-screen margin_vertical_xl'}>
                <img src={window.view_data.imgs.iphone_with_banner_purple} className={'iphone'}/>
                <div className={'content'}>
                    <div className={'title'}>
                        {this.props.store.activeQuestion.title}
                    </div>
                    <div className={'question_text'}>
                        {this.props.store.activeQuestion.question_text}
                    </div>
                    <AnswerButtons />
                </div>
                <div className={'footer_row'}>
                    <div className={'footer_space'}>
                        {
                            this.props.store.activeQuestion.has_back_button && !isConfirmationScreen &&
                            <div className={'back_button'}>
                                {backText}
                            </div>
                        }
                        <div className={'next_button'}>
                            {nextText}
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    render(){

        return (
            <div className={'w100 text-center margin_vertical_lg no-select'}>
                {this.body}
                {
                    this.props.store.activeQuestion.translation &&
                    <div className={'mobile-screen margin_vertical_xl'}>
                        {this.body}
                    </div>
                }
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        store: state
    };
}

export default connect(mapStateToProps, {
})(DeviceWorkarea)
