import React, {Component} from 'react'
import {connect} from "react-redux";
import {setQuestion} from "../Designer/redux/actions";

class ItemTypeHandler extends Component {

    constructor(props) {
        super(props)
        this.rotateType = this.rotateType.bind(this)
    }

    rotateType(){
        const { item } = this.props
        this.props.setQuestion(item.id, 'type', this.nextType(item.type))
    }

    nextType(type){
        switch(type){
            case 'item':
                return 'integration'
            case 'integration':
                return 'value'
            case 'value':
                return 'header'
            default:
                return 'item'
        }
    }

    display(type){
        switch(type){
            case 'item':
                return 'Checkoff Item'
            case 'integration':
                return 'Integration'
            case 'value':
                return 'Playbook Question'
            case 'header':
                return 'Section Header'
            default:
                return '--'
        }
    }

    render() {
        const {item} = this.props
        return (
            <div className={'inline-block float-right'} onClick={this.rotateType}>
                <div className={'playbook-item-type'}>
                    {this.display(item.type)}
                </div>
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        store: state
    };
}

export default connect(mapStateToProps, {
    setQuestion,
})(ItemTypeHandler)
