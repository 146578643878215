import React, {Component} from "react";
import ReactDOM from "react-dom";
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import {reorder} from '../utils/dndReorder'
import Tile from './TileContainer/Tile'
import {click, setQuestionOrder, setProperties, setFiltered, addNewQuestion} from './Designer/redux/actions'
import {connect} from "react-redux";
import HeaderTile from "./TileContainer/HeaderTile";
import ValueTile from "./TileContainer/ValueTile";
import IntegrationTile from "./TileContainer/IntegrationTile";


class TileContainer extends Component {
    constructor(props) {
        super(props);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.state = { filtered: 'NEW'}
    }

    getFilteredQuestions() {
        const filtered = this.props.store.filtered || 'NEW';
        const questions = this.props.store.questions.filter((q)=> q.status === filtered);

        return questions;
    }

    onDragEnd(result) {
        const filtered = this.props.store.filtered || 'NEW'
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const questions = reorder(
            this.getFilteredQuestions(),
            result.source.index,
            result.destination.index
        );

        console.log([...questions].map((q)=>{
            return {
                title: q.title,
                id: q.id,
                status: q.status
            }

        }),
            result.source.index,
            result.destination.index)

        this.props.setQuestionOrder([...questions]);
    }

    render() {
        const filtered = this.props.store.filtered || 'NEW'
        return (
            <div className={'tile-container-container'}>
                <b className="margin_left_xs">{this.props.store.playbook.name}</b>
                <div className={'archived'} onClick={() => this.props.setFiltered(filtered === 'ARCHIVED' ? 'NEW' : 'ARCHIVED')}>
                    {filtered === 'ARCHIVED' ? 'Current' : 'Archived'}
                </div>
                <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                className={`tile-container ${snapshot.isDraggingOver ? 'dragging' : ''}`}
                            >

                                {
                                    this.getFilteredQuestions().map((item, index) => {
                                            switch(item.type){
                                                case 'header':
                                                    return (
                                                        <HeaderTile
                                                            item={item}
                                                            index={index}
                                                        />
                                                    )
                                                case 'integration':
                                                    return (
                                                        <IntegrationTile
                                                            item={item}
                                                            index={index}
                                                        />
                                                    )
                                                case 'value':
                                                    return (
                                                        <ValueTile
                                                            item={item}
                                                            index={index}
                                                        />
                                                    )

                                                default:
                                                    return (
                                                        <Tile
                                                            item={item}
                                                            index={index}
                                                        />
                                                    )
                                            }

                                    })
                                }
                                <div onClick={this.props.addNewQuestion}>
                                    <div className="add-question-button">
                                        Add Item
                                    </div>
                                </div>
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        store: state
    };
}

export default connect(mapStateToProps, {
    setQuestionOrder,
    setProperties,
    setFiltered,
    addNewQuestion,
})(TileContainer)
