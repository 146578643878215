import React, {Component} from 'react'
import {connect} from 'react-redux';
import {click} from './redux/actions'
import TileContainer from "../TileContainer";
import {patchState} from "./redux/actions";
import {setProperties} from "./redux/actions";
import UserNav from "../../UserNav";

import ServerSaver from './redux/ServerSaver'
import isMobile from "../../utils/isMobile";


class Body extends Component {

    constructor(props) {
        super(props)
        this.state = {
            serverSaver: new ServerSaver(this.props.patchState),
            firstEntry: props.firstEntry,
            isMobile: isMobile(),
        }
    }

    componentDidMount(){
        if(this.state.firstEntry){
            window.alert('Streamliner will autosave your progress as you create. If there is any trouble saving the page will alert you.')
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.store.statusMessage) {
            const self = this
            setTimeout(() => {
                self.props.setProperties({statusMessage: null})
            }, 2200)
        }

        this.state.serverSaver.save(this.props)
    }

    render() {
        const {store} = this.props


        return (
            <div className='whole-body category-body'>
                <UserNav/>

                <div>
                    <div className={'row no-margin'}>
                        <img src={window.view_data.imgs.nav_banner_sm} className={'nav-banner'}/>
                <TileContainer
                />
                    </div>
                </div>
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        store: state
    };
}

export default connect(mapStateToProps, {
    click,
    setProperties,
    patchState,
})(Body)
