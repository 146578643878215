import React, {Component} from 'react'
import {connect} from "react-redux";
import {setQuestion} from "../Designer/redux/actions";

class Question extends Component {

    constructor(props) {
        super(props)
        this.rotateType = this.rotateType.bind(this)
        this.updateProperty = this.updateProperty.bind(this)
    }

    rotateType(){
        const { question } = this.props
        this.updateProperty(question.id, 'type', this.nextType(question.type))
    }

    updateProperty(id, key, value){
        const questions = this.props.item.questions || []
        const {item} = this.props

        const newQuestions = questions.map((w)=>{
            if(w.id === id){
                return {...w,[key]:value}
            } else {
                return w
            }
        })

        this.props.setQuestion(item.id, 'questions', newQuestions)
    }

    nextType(type){
        switch(type){
            case 'text':
                return 'numeric'
            default:
                return 'text'
        }
    }

    removeQuestion(question) {
        const {item} = this.props
        const questions = this.props.item.questions || []
        this.props.setQuestion(item.id, 'questions', questions.filter((w) => (w.id !== question.id)))
    }

    render() {
        const {item, question, i} = this.props
        return (
            <div className="w100" key={`question-${i}`}>
                <div className="question-block">
                    <div className="inline-block question-route w100">
                        <div className="float-right question-remove-button" onClick={() => this.removeQuestion(question)}>x</div>
                        Question: <input
                            type={'text'}
                            className={'inline-block w100 question-question-input'}
                            placeholder={'Ex. What is the Customer Id?'}
                            required
                            value={question.question}
                            onChange={(e)=>this.updateProperty(question.id, 'question', e.target.value)}
                        />
                        <div className="w100">
                            <div className={'inline-block w50'}>
                                Answer name: <input
                                    type={'text'}
                                    className={'inline-block w100 question-name-input'}
                                    placeholder={'Ex. customer_id'}
                                    required
                                    value={question.name}
                                    onChange={(e)=>this.updateProperty(question.id, 'name', e.target.value)}
                                />
                            </div>
                            <div>
                                Answer type: <div className={'inline-block http-type'} onClick={this.rotateType}>{question.type}</div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        store: state
    };
}

export default connect(mapStateToProps, {
    setQuestion,
})(Question)
