import React, {Component} from 'react'
import {connect} from 'react-redux';
import VersionControlType from "./ColumnControl/VersionControlType";
import QuestionControlType from "./ColumnControl/QuestionControlType";
import UtilityBar from "./UtilityBar";

class ControlColumn extends Component {

    get typeToShow() {
        if (this.props.store.controlView) {
            return this.props.store.controlView
        } else if (this.props.activeQuestion) {
            return 'active_question'
        } else {
            return ''
        }
    }

    render() {
        const {store} = this.props
        return (
            <div className={'col-lg-4'}>
                <div className={'margin_vertical_md question-control-card no-select'}>
                <UtilityBar/>
                    {
                        this.typeToShow === 'version_control' &&
                        <VersionControlType
                        />
                    }
                    {
                        this.typeToShow === 'active_question' &&
                        <QuestionControlType />
                    }
                </div>
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        store: state
    };
}

export default connect(mapStateToProps, {
})(ControlColumn)
