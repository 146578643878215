import React, {Component} from 'react'
import {connect} from 'react-redux';
import {removeCategory} from '../redux/actions'
import {inactivateCategory, activateCategory} from "../../../services/category";

class ArchiveAndRestore extends Component {


    async activateCategory(id) {
        const resp = await activateCategory(this.props, id)
        this.props.removeCategory(resp.category)
    }

    async inactivateCategory(id) {
        const resp = await inactivateCategory(this.props, id)
        this.props.removeCategory(resp.category)
    }

    render() {
        const { category } = this.props
        if(category.status === 'NEW'){
            return (
                <div className={'restore-category'} onClick={(e) => {
                    e.stopPropagation();
                    console.log(category)
                    this.inactivateCategory(category.id)
                }}>
                    {'Archive'}
                </div>
            )
        } else {
            return (
                <div className={'restore-category'} onClick={(e) => {
                    e.stopPropagation();
                    console.log(category)
                    this.activateCategory(category.id)
                }}>
                    {'Restore'}
                </div>
            )
        }

    }

}

function mapStateToProps(state) {
    return {
        store: state
    };
}

export default connect(mapStateToProps, {
    removeCategory
})(ArchiveAndRestore)
