import React, {Component} from 'react'
import {connect} from 'react-redux';
import {changeActiveQuestionValue, updateAnswer} from "./../redux/actions";
import defaultAnswer from "../../utils/defaultAnswer";

class TwoAnswerControl extends Component {

    componentDidMount() {
        let answers = this.props.store.activeQuestion.answers
        if(answers.length < 2){
            for(let i = answers.length; i < 2; i++){
                answers = [...answers, {
                    ...defaultAnswer,
                    index: i,
                    value: i
                }]
            }

            this.props.changeActiveQuestionValue('answers', answers)
        }
    }

    render() {
        const {answers} = this.props.store.activeQuestion
        return (
            <div className={'w100'}>
                <div className={'w100'}>
                    {
                        answers.length > 0 &&
                        <div className={'row-style w100'}>
                            <div className={'row-cell text-left'}>
                                <div className={'answer-label'}>
                                    TEXT
                                </div>
                            </div>
                            <div className={'row-cell text-left padding_left_lg'}>
                                <div className={'answer-label margin_left_xl'}>
                                    SCORE
                                </div>
                            </div>
                        </div>
                    }
                    {
                        answers.map((answer, index) => {
                            if(index < 2) {
                                return (
                                    <div className={'row-style w100'} key={`control-${index}`}>
                                        <div className={'row-cell-lg text-left'}>
                                            <input
                                                className="w100-important inline-block"
                                                value={answer.displayText}
                                                type={'text'}
                                                onChange={(e) => {
                                                    this.props.updateAnswer({
                                                        ...answer,
                                                        displayText: e.target.value,
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className={'row-cell text-left'}>
                                            <input
                                                className="small-input margin_left_sm inline-block"
                                                value={answer.value}
                                                type={'number'}
                                                onChange={(e) => {
                                                    this.props.updateAnswer({
                                                        ...answer,
                                                        value: Number(e.target.value),
                                                    })
                                                }}

                                            />
                                        </div>
                                    </div>
                                )
                            }
                        })
                    }
                </div>
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        store: state
    };
}

export default connect(mapStateToProps, {
    changeActiveQuestionValue,
    updateAnswer,
})(TwoAnswerControl)
