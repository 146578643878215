import React, {Component} from 'react'
import {connect} from 'react-redux';
import {changeActiveQuestionValue, updateAnswer} from "./../redux/actions";
import defaultAnswer from "../../utils/defaultAnswer";

class NumericRangeControl extends Component {

    componentDidMount() {
        let answers = this.props.store.activeQuestion.answers
        if (answers.length < 1) {
            for (let i = answers.length; i < 1; i++) {
                answers = [...answers, {
                    ...defaultAnswer,
                    index: i,
                    value: i,
                }]
            }

            this.props.changeActiveQuestionValue('answers', answers)
        }
    }

    render() {
        const {answers} = this.props.store.activeQuestion
        const answer = answers[0]
        return (
            <div className={'w100'}>
                <div className={'w100'}>
                    {
                        answers.length > 0 &&
                        <div className={'row-style w100'}>
                            <div className={'row-cell text-left'}>
                                <div className={'answer-label'}>
                                    MIN
                                </div>
                            </div>
                            <div className={'row-cell text-left padding_left_lg'}>
                                <div className={'answer-label margin_left_xl'}>
                                    MAX
                                </div>
                            </div>
                        </div>
                    }
                    {
                        answer &&
                        <div className={'row-style w100'}>
                            <div className={'row-style w100'}>
                                <div className={'row-cell-lg text-left'}>
                                    <input
                                        className="small-input margin_left_sm inline-block"
                                        value={answer.min}
                                        type={'number'}
                                        onChange={(e) => {
                                            this.props.updateAnswer({
                                                ...answer,
                                                min: Math.max(0, Number(e.target.value)),
                                            })
                                        }}

                                    />
                                </div>
                                <div className={'row-cell text-left'}>
                                    <input
                                        className="small-input inline-block"
                                        value={answer.max}
                                        type={'number'}
                                        onChange={(e) => {
                                            this.props.updateAnswer({
                                                ...answer,
                                                max: Math.min(10, Number(e.target.value)),
                                            })
                                        }}

                                    />
                                </div>
                            </div>
                            <div className={'row-style w100 margin_top_sm'}>
                                <div className={'answer-label margin_top_sm'}>
                                    MIN DESC.
                                </div>
                                <input
                                    className="margin_left_sm inline-block margin_top_sm"
                                    value={answer.min_description}
                                    type={'text'}
                                    onChange={(e) => {
                                        this.props.updateAnswer({
                                            ...answer,
                                            min_description: e.target.value,
                                        })
                                    }}
                                />
                            </div>
                            <div className={'row-style w100 margin_top_sm'}>
                                <div className={'answer-label margin_top_sm'}>
                                    MAX DESC.
                                </div>
                                <input
                                    className="margin_left_sm inline-block margin_top_sm"
                                    value={answer.max_description}
                                    type={'text'}
                                    onChange={(e) => {
                                        this.props.updateAnswer({
                                            ...answer,
                                            max_description: e.target.value,
                                        })
                                    }}
                                />
                            </div>
                        </div>

                    }

                </div>
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        store: state
    };
}

export default connect(mapStateToProps, {
    changeActiveQuestionValue,
    updateAnswer,
})(NumericRangeControl)
