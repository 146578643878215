import React, {Component} from 'react'
import {connect} from 'react-redux';

class MultiButton extends Component {


    render() {
        return (
            <div className={'w100 margin_top_lg'}>
                <div>
                    {
                        this.props.store.activeQuestion.answers.map((answer, i) => {
                            return (
                                <div className={'multi-button-container'} key={i}>
                                    <div className={'multi-button'}>
                                        {answer.displayText}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        store: state
    };
}

export default connect(mapStateToProps, {})(MultiButton)
