import React, { Component } from 'react'
import { connect } from "react-redux";
import { setQuestion } from "../Designer/redux/actions";
import Headers from './Webhook/Headers'
import Params from './Webhook/Params'

class WebHook extends Component {

    constructor(props) {
        super(props)
        this.rotateType = this.rotateType.bind(this)
        this.updateProperty = this.updateProperty.bind(this)
    }

    rotateType() {
        const { webhook } = this.props
        this.updateProperty(webhook.id, 'type', this.nextType(webhook.type))
    }

    updateProperty(id, key, value) {
        const webhooks = this.props.item.webhooks || []
        const { item } = this.props
        const newWebhooks = webhooks.map((w) => {
            if (w.id === id) {
                return { ...w, [key]: value }
            } else {
                return w
            }
        })
        this.props.setQuestion(item.id, 'webhooks', newWebhooks)
    }

    nextType(type) {
        switch (type) {
            case 'POST':
                return 'GET'
            case 'GET':
                return 'PUT'
            case 'PUT':
                return 'DELETE'
            default:
                return 'POST'
        }
    }

    removeWebhook(webhook) {
        const { item } = this.props
        const webhooks = this.props.item.webhooks || []
        this.props.setQuestion(item.id, 'webhooks', webhooks.filter((w) => (w.id !== webhook.id)))
    }

    render() {
        const { item, webhook, i } = this.props
        return (
            <div className="w100" key={`webhook-${i}`}>
                <div className="webhook-block">
                    <div className="inline-block webhook-route w100">
                        <div className={'inline-block http-type'} onClick={this.rotateType}>{webhook.type}</div>
                        <div className={'inline-block route w50'}>
                            <input
                                type={'text'}
                                className={'inline-block w100 webhook-route-input'}
                                value={webhook.route}
                                onChange={(e) => this.updateProperty(webhook.id, 'route', e.target.value)}
                            />
                        </div>
                        <div className="float-right remove-button" onClick={() => this.removeWebhook(webhook)}>remove</div>
                    </div>
                    <div className={'w100'}>
                        <Params
                            item={item}
                            webhook={webhook}
                        />
                        <Headers
                            item={item}
                            webhook={webhook}
                        />
                    </div>
                </div>
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        store: state
    };
}

export default connect(mapStateToProps, {
    setQuestion,
})(WebHook)
