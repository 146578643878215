import React, {Component} from 'react'
import {connect} from 'react-redux';
import MultiButtonAnswerControl from "./MultiButtonAnswerControl";
import TwoAnswerControl from "./TwoAnswerControl";
import NumericRangeControl from "./NumericRangeControl";
import OpenEndedControl from "./OpenEndedControl";
import NumberControl from "./NumberControl";
import SignControl from "./SignControl";

class AnswerControl extends Component {

    render() {
        return (
            <div className={'w100 bold-header'}>

                {
                    this.props.store.activeQuestion.question_type === 'informational' &&
                    <div></div>
                }
                {
                    this.props.store.activeQuestion.question_type === 'multi-button' &&
                    <div>
                        <label>Answers</label>
                        <MultiButtonAnswerControl />
                    </div>
                }
                {
                    this.props.store.activeQuestion.question_type === 'two-answer' &&
                    <div>
                        <label>Answers</label>
                        <TwoAnswerControl />
                    </div>
                }
                {
                    this.props.store.activeQuestion.question_type === 'long-answer' &&
                    <div>
                        <OpenEndedControl />
                    </div>
                }
                {
                    this.props.store.activeQuestion.question_type === 'numeric-rating' &&
                    <div>
                        <label>Range</label>
                        <NumericRangeControl />
                    </div>
                }
                {
                    this.props.store.activeQuestion.question_type === 'number' &&
                    <div>
                        <label>Number</label>
                        <NumberControl />
                    </div>
                }
                {
                    this.props.store.activeQuestion.question_type === 'sign' &&
                    <div>
                        <SignControl />
                    </div>
                }
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        store: state
    };
}

export default connect(mapStateToProps, {})(AnswerControl)
