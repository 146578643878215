import React, {Component} from 'react'
import {connect} from 'react-redux';
import {patch} from '../redux/actions'

class CustomLineItems extends Component {

    constructor(props) {
        super(props)
        this.add = this.add.bind(this)
        this.remove = this.remove.bind(this)
        this.changeField = this.changeField.bind(this)
    }

    add() {
        this.props.patch({customLineItems: [...this.customLineItems, {}]})
    }

    remove(index) {
        const customLineItems = this.customLineItems
            .map((li, i) => {
                return index === i ? null : li
            })
            .filter((li) => li != null)

        this.props.patch({customLineItems})
    }

    changeField(index, field, text) {
        const customLineItems = this.customLineItems.map((li, i) => {
            if (index === i) {
                return {
                    ...li,
                    [field]: text,
                }
            } else {
                return li
            }
        })
        this.props.patch({customLineItems})
    }

    get customLineItems() {
        return this.props.store.customLineItems
    }


    get lineItems() {

        return this.props.store.customLineItems.map((item, index) => {
            return (
                <div className={'custom-line-item'}>
                    <div className={'remove'} onClick={() => this.remove(index)}>remove (x)</div>
                    <div className={'w100 margin_top_sm'}>
                        <b>Line Item Name</b>
                        <input
                            className={'input-name'}
                            type={'text'}
                            name={`custom_line_item_${index}[name]`}
                            onChange={(e) => this.changeField(index, 'name', e.target.value)}
                            value={item.name}
                            placeholder={'Ex. Post-Category Analysis'}/>
                    </div>
                    <div className={'w100 margin_top_sm'}>
                        <b>Price (USD)</b>
                        <input
                            className={'input-name'}
                            type={'number'}
                            name={`custom_line_item_${index}[price]`}
                            onChange={(e) => this.changeField(index, 'price', e.target.value)}
                            value={item.price}
                            placeholder={'Ex. 5800'}
                        />
                    </div>
                    <div className={'w100 margin_top_sm'}>
                        <b>Description</b>
                        <textarea
                            className={'input-description'}
                            type={'text'}
                            name={`custom_line_item_${index}[desc]`}
                            onChange={(e) => this.changeField(index, 'description', e.target.value)}
                            value={item.description}
                            placeholder={'Ex. Post-category analysis report with...'}
                        />
                    </div>
                </div>
            )
        })

    }

    render() {
        return (
            <div className={'w100 text-left margin_top_lg'}>
                <h4 className={'margin_top_md'}>Custom Line Items</h4>
                <div className="margin_top_sm">
                    {
                        this.customLineItems.length > 0 &&
                        <div className={'w100'}>
                            {this.lineItems}
                        </div>
                    }
                    {
                        this.customLineItems.length <= 0 &&
                        <div className="padded_sm">
                            <div className="w100 subtle-grey-text">
                                No reseller line items added,
                                <a className={''} onClick={this.add}>
                                    click here to (+) add custom line item
                                </a>
                            </div>
                        </div>
                    }

                </div>
                {
                    this.customLineItems.length > 0 &&
                    <a className={'float-right margin_top_lg'} onClick={this.add}>
                        (+) Add custom line item
                    </a>
                }
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        store: state
    };
}

export default connect(mapStateToProps, {
    patch,
})(CustomLineItems)
