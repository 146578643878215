import React, {Component} from 'react'
import {connect} from 'react-redux';

class TwoAnswer extends Component {


    render() {
        const answers = this.props.store.activeQuestion.answers
        return (
            <div className={'w100 margin_top_sm padded_sm'}>
                <div>
                    {
                        answers[0] &&
                        <div className={'two-button-container left'}>
                            <div className={'two-button'}>
                                {answers[0].displayText}
                            </div>
                        </div>
                    }
                    {
                        answers[1] &&
                        <div className={'two-button-container right'}>
                            <div className={'two-button'}>
                                {answers[1].displayText}
                            </div>
                        </div>
                    }


                </div>
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        store: state
    };
}

export default connect(mapStateToProps, {})(TwoAnswer)
