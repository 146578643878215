import defaultQuestion from "../../utils/defaultQuestion";

export default (state = {}, action) => {
    let new_state = state;
    let newActiveQuestion = null

    switch (action.type) {
        case 'set_question_order':
            new_state = {
                ...state,
                questions: action.questions
            }
            break;
        case 'set_active_question':
            new_state = {
                ...state,
                activeQuestion: action.question,
            }
            break;
        case 'set_question':
            new_state = {
                ...state,
                questions: state.questions.map((q)=>{
                    if(q.id == action.questionId){
                        return {
                            ...q,
                            [action.field]: action.value
                        }
                    } else {
                        return {
                            ...q
                        }
                    }
                })
            }
            break;
        case 'patch_state':
            new_state = {
                ...state,
                ...action.newState,
            }
            break;
        case 'set_active_question_next':
            new_state = {
                ...state,
                activeQuestion: nextQuestion(state, state.questions, action.removedQuestion),
                statusMessage: action.statusMessage,
            }
            break;
        case 'change_active_question_value':
            newActiveQuestion = {
                ...state.activeQuestion,
                [action.key]: action.value,
            }

            new_state = {
                ...state,
                activeQuestion: newActiveQuestion,
                questions: syncActiveQuestion(state.questions, newActiveQuestion)
            }
            break;
        case 'update_answer':
            newActiveQuestion = {
                ...state.activeQuestion,
                'answers': state.activeQuestion.answers.map((a) => {
                    if (a.index === action.answer.index) {
                        return {
                            ...a,
                            ...action.answer
                        }
                    } else {
                        return a
                    }
                }),
            }

            new_state = {
                ...state,
                activeQuestion: newActiveQuestion,
                questions: syncActiveQuestion(state.questions, newActiveQuestion)
            }
            break;
        case 'click':
            new_state = {
                ...state,
                hi: state.hi + 1
            }
            break;
        case 'set_properties':
            new_state = {
                ...state,
                ...action.propertyObj,
            }
            break;
        case 'set_filtered':
            new_state = {
                ...state,
                filtered: action.filtered,
            }
            new_state = {
                ...new_state,
                activeQuestion: nextQuestion(new_state, state.questions, new_state.activeQuestion || {}),
            }
            break;
        case 'add_new_question':
            new_state = {
                ...state,
                questions: addQuestion(state.questions)
            }
            break;
    }

    return new_state

}
const addQuestion = (questions)=>{
    let highestIndex = -1

    questions.forEach((q)=>{
        highestIndex = Math.max(highestIndex, q.index)
    })

    let newIndex = highestIndex + 1

    return [...questions, {
            ...defaultQuestion,
            id: `item-${newIndex}`,
            title: `Checklist Item ${newIndex+1}`,
            index: newIndex,
    }]
}

const nextQuestion = (state, questions, currentQuestion) => {
    let index = 0
    console.log(questions, state)
    const questionsOfStatus = questions.filter((q)=>q.status===state.filtered)
    questionsOfStatus.forEach((q, i) => {
        if (q.id === currentQuestion.id) {
            index = i + 1
        }
    })

    const nextIndex = Math.min(questions.length - 1, index)
    return questionsOfStatus[nextIndex]
}

const syncActiveQuestion = (questions, newActiveQuestion) => {
    return questions.map((q) => {
        return q.id === newActiveQuestion.id ? newActiveQuestion : q
    })
}
