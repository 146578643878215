import React, {Component} from 'react'
import {connect} from 'react-redux';
import ProposalHelper from '../../../utils/ProposalHelper'

class Summary extends Component {

    constructor(props) {
        super(props)
    }

    get monthlyPayment() {
        return this.format(ProposalHelper.monthlyPayment(this.props.store))
    }

    get monthToMonthPayment() {
        return this.format(ProposalHelper.monthToMonthPayment(this.props.store))
    }

    format(raw) {
        return Math.round(Number(raw)).toString().toLocaleString('en-US')
    }

    get grandTotal() {
        return this.format(ProposalHelper.grandTotal(this.props.store))
    }

    get sum() {
        return this.format(ProposalHelper.sum(this.props.store))
    }

    get totalDT() {
        return this.format(ProposalHelper.totalDT(this.props.store))
    }

    get margin() {
        return this.format(ProposalHelper.resellerMargin(this.props.store))
    }

    get monthlyMargin() {
        return this.format(ProposalHelper.monthlyResellerMargin(this.props.store))
    }

    get monthToMonthMargin() {
        return this.format(ProposalHelper.monthToMonthResellerMargin(this.props.store))
    }

    get sumWithMarkup() {
        return this.format(ProposalHelper.sumWithMarkup(this.props.store))
    }

    render() {
        return (
            <div className={'row no-margin'}>
                <div className={'col-lg-2 col-sm-12'}></div>
                <div className={'col-lg-8 col-sm-12 text-left'}>
                    <h2 className="margin_top_lg"><div className={'section-number'}>2</div>Summary</h2>
                    {
                        Number(this.props.store.duration) <= 1 &&  this.props.store.proposal_type !== 'sdc' &&
                        <div className={'proposal-summary'}>
                            <h5>
                                <span className={'prefix-label lg'}>$</span>490 upon deployment
                            </h5>
                            <div className={'margin_top_md'}>Total cost of<span className={'prefix-label'}>$</span><span>{this.format(this.sumWithMarkup)}</span></div>
                            <div className={'margin_top_sm'}>&nbsp;&nbsp;&nbsp;◦ $490 is deducted from total cost and remainder of payment due fourteen (14) days after deployment</div>

                            <div className={'terms-explained asterisk'}>*<i>Note that data can be collected for an indefinite period of time. </i></div>

                            <div className="margin_top_md">
                                {
                                    this.margin > 0 &&
                                    <div>
                                        <div><b>Cost:</b> {this.sum}</div>
                                        <div><b>Reseller:</b></div>
                                        <div className={'padded_sm_horizontal'}>
                                            <div><b>Total margin:</b> {this.margin}</div>
                                        </div>
                                    </div>
                                }

                                <input name="grand_total" value={this.grandTotal} hidden readOnly={true}/>
                                <input name="total_dt" value={this.totalDT} hidden readOnly={true}/>
                                <input name="total_margin" value={this.margin} hidden readOnly={true}/>
                            </div>
                        </div>
                    }
                    {
                        this.props.store.proposal_type === 'sdc' &&
                        <div className={'proposal-summary'}>
                            <h5>
                                <span className={'prefix-label lg'}>$</span>490 upon deployment
                            </h5>
                            <div className={'margin_top_md'}>Monthly cost of<span className={'prefix-label'}>$</span><span>{this.format(this.sumWithMarkup)} </span>/mo</div>
                            <div className={'margin_top_sm'}>&nbsp;&nbsp;&nbsp;◦ First monthly payment is not due until one (1) month after deployment</div>
                            <div>&nbsp;&nbsp;&nbsp;◦ Month-to-month, no-contract</div>

                            <div className={'terms-explained asterisk'}>*<i>Note that after a category is turned-off, collected data can still be downloaded, but new responses can no longer be collected from patients. </i></div>

                            <div className="margin_top_md">
                                {
                                    this.margin > 0 &&
                                    <div>
                                        <div><b>Cost:</b> {this.sum}</div>
                                        <div><b>Reseller:</b></div>
                                        <div className={'padded_sm_horizontal'}>
                                            <div><b>Total margin:</b> {this.margin}</div>
                                        </div>
                                    </div>
                                }

                                <input name="grand_total" value={this.grandTotal} hidden readOnly={true}/>
                                <input name="total_dt" value={this.totalDT} hidden readOnly={true}/>
                                <input name="total_margin" value={this.margin} hidden readOnly={true}/>
                            </div>
                        </div>
                    }
                    {
                        Number(this.props.store.duration) > 1 &&
                        <div className={'proposal-summary'}>
                            <h5>Cost: <span className={'prefix-label'}>$</span>
                                <span className={'accent'}>{this.format(this.monthlyPayment)}</span> <span
                                    className={'suffix-label'}>/mo</span>
                            </h5>
                            <div>
                                {this.props.store.duration} mos contract,
                                ${this.format(this.monthToMonthPayment)} (optional) monthly afterwards


                                <div className="margin_top_md">
                                    <div><b>Cost:</b> {this.sum}</div>
                                    <div><b>Reseller:</b></div>
                                    <div className={'padded_sm_horizontal'}>
                                        <div><b>Total margin (contract):</b> {this.margin}</div>
                                        <div><b>Monthly margin (contract):</b> {this.monthlyMargin}</div>
                                        <div><b>Monthly margin (post-contract,
                                            month-to-month):</b> {this.monthToMonthMargin}</div>
                                    </div>
                                    <div><b>Total:</b> {this.grandTotal}</div>
                                </div>

                                <input name="grand_total" value={this.grandTotal} hidden/>
                                <input name="total_dt" value={this.totalDT} hidden/>
                                <input name="total_margin" value={this.margin} hidden/>
                                <input name="monthly_payment" value={this.monthlyPayment} hidden/>
                                <input name="monthly_margin" value={this.monthlyMargin} hidden/>
                                <input name="monthly_post_contract_payment" value={this.monthToMonthPayment} hidden/>
                                <input name="monthly_post_contract_margin" value={this.monthToMonthMargin} hidden/>
                            </div>
                        </div>
                    }


                    <input type={'submit'} className="cdp-btn-submit float-right margin_top_md" value={'Request Deployment'} />
                </div>
                <div className={'col-lg-2 col-sm-12'}></div>
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        store: state
    };
}

export default connect(mapStateToProps, {})(Summary)
