// var config = require('../config.json');
// import host from './host'

export const createCategory = async (props, category)=>{
    const response = await fetch(`/category`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRF-Token': props.authenticationToken,

        },
        body: JSON.stringify({
            ...category
        }),
    })

    return await response.json()
}


export const patchCategory = async (props, id, category)=>{
    const response = await fetch(`/category/${id}/patch`, {
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRF-Token': props.authenticationToken,

        },
        body: JSON.stringify({
            ...category
        }),
    })

    return await response.json()
}
export const activateCategory = async (props, id)=>{
    const response = await fetch(`/category/${id}/activate`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRF-Token': props.authenticationToken,

        },
        body: JSON.stringify({ }),
    })

    return await response.json()
}

export const inactivateCategory = async (props, id)=>{
    const response = await fetch(`/category/${id}/inactivate`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRF-Token': props.authenticationToken,

        },
        body: JSON.stringify({ }),
    })

    return await response.json()
}
