import React, {Component} from 'react'
import {connect} from 'react-redux';
import {checkoff} from '../../../../services/runs'
import 'react-quill/dist/quill.snow.css';
import Subquestion from "./CheckoffItem/Subquestion";

class CheckoffItem extends Component {


    constructor(props) {
        super(props)
        this.onChange = this.onChange.bind(this)
        this.state = {
            checkbox: this.checked(props),
        }
    }

    checked(props){
        const {item} = props
        const runState = item.runState.find((s)=>s.key === 'checkoff')
        return runState != null
    }

    get checkoffItem(){
        const {item} = this.props
        return item.runState.find((s)=>s.key === 'checkoff') || {}
    }

    async onChange(){
        const {item} = this.props
        const newValue = !this.state.checkbox
        this.setState({ checkbox: newValue})
        await checkoff(this.props, item.id, newValue)
    }

    render() {
        const {item} = this.props

        return (
            <div className={'checkoff-item default-item'}>
                <input
                    type={'checkbox'}
                    checked={this.state.checkbox}
                    value={this.state.checkbox}
                    onChange={this.onChange}
                /> {item.title}
                {
                    this.checked(this.props) &&
                    <div className="checkoff completed_by">
                        Completed by {this.checkoffItem.actor.first} {this.checkoffItem.actor.last}
                    </div>
                }
                <div className="padded_question_horizontal">
                    <div className={'padded_sm question-item-subtext ql-editor'}>
                        <div className={''} dangerouslySetInnerHTML={{ __html: item.description }} />
                    </div>
                    <div>
                        {
                            (item.questions||[]).map((subquestion)=>{
                                return (
                                    <Subquestion
                                        item={item}
                                        subquestion={{
                                            ...subquestion,
                                            // runState: []
                                        }}
                                    />
                                )
                            })
                        }

                    </div>
                </div>
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        store: state
    };
}

export default connect(mapStateToProps, {
})(CheckoffItem)
