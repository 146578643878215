import React, {Component} from 'react'
import {connect} from 'react-redux';
import {changeActiveQuestionValue, updateAnswer} from "./../redux/actions";
import defaultAnswer from "../../utils/defaultAnswer";

class SignControl extends Component {

    componentDidMount() {
        let answers = this.props.store.activeQuestion.answers
        if (answers.length < 1) {
            for (let i = answers.length; i < 1; i++) {
                answers = [...answers, {
                    ...defaultAnswer,
                    index: i,
                    value: i,
                }]
            }

            this.props.changeActiveQuestionValue('answers', answers)
        }
    }

    render() {
        const {answers} = this.props.store.activeQuestion
        const answer = answers[0]
        return (
            <div className={'w100'}>
                <div className={'w100'}>

                </div>
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        store: state
    };
}

export default connect(mapStateToProps, {
    changeActiveQuestionValue,
    updateAnswer,
})(SignControl)
