import React, {Component} from 'react'
import {connect} from 'react-redux';
import {addRun, removeRun, touchRuns, updateRun} from './redux/actions'
import UserNav from "../../UserNav";
import {createRun, postUpdate} from "../../services/runs";
import Checklist from './components/Checklist'

class Body extends Component {

    constructor(props) {
        super(props)
        this.addBlankRun = this.addBlankRun.bind(this)
        this.searchFilter = this.searchFilter.bind(this)
        this.postUpdate = this.postUpdate.bind(this)
        this.state = {
            search: '',
        }
    }

    searchFilter(p) {
        if (this.state.search) {
            return `${p.name} ${p.id}`.toLowerCase().includes(this.state.search.toLowerCase())
        } else {
            return p
        }
    }

    async addBlankRun() {
        const blankRun = {name: 'New Run', playbook_id: this.state.playbookId}
        const resp = await createRun(this.props, blankRun)
        this.props.addRun(resp.run)
    }

    async postUpdate() {
        alert('Feature needed: IF a value for `discord_webhook` exists, we should show a modal that pushes a custom message to Discord. IF NOT, need to display message suggesting to: "Please create a `discord_webhook` question and store a webhook value."')
        // const resp = await postUpdate(this.props, this.props?.store?.run)
    }

    get filteredRuns(){
        const search = this.state.search.toLowerCase()
        return this.props.store.runs.filter((r)=>(r.name.toLowerCase().indexOf(search) > -1))
    }

    get integrationItems(){
        return this.props.store.questions.filter((q)=>q.type==='integration')
    }

    render() {
        const {run, playbook, runs} = this.props.store
        return (
            <div className='whole-body category-body'>
                <UserNav/>

                <div>
                    <div className={'row no-margin'}>
                        <img src={window.view_data.imgs.nav_banner_sm} className={'nav-banner'}/>
                        <div className={'col-lg-2'}>
                            <div className="margin_bottom_sm">
                                <a href="/runs">View All Runs</a>
                                <hr/>
                                <input onChange={(e)=>this.setState({search: e.target.value})} />
                            </div>
                            {
                                this.filteredRuns.map((r)=>{
                                    return (
                                        <div>
                                            <a href={`/runs/${r.id}`}>{r.name}</a>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className={'col-lg-8 margin_top_md'}>
                            <small>
                                {
                                    this.props.store.origin !== 'runs_for' &&
                                    <a href={`/runs`}>View All Runs</a>
                                }
                                {
                                    this.props.store.origin === 'runs_for' &&
                                    <a href={`/runs_for/${playbook.id}`}>View Runs For</a>
                                }
                                >
                                <a href={`/runs/${run.id}`}>Run: {run.name}</a>
                            </small>
                            <div className="margin_top_md">
                                <h3 className={'margin_left_xs'}>
                                    {run.name}
                                    <div className="inline-block integration-row">
                                        {
                                            this.integrationItems.map((q)=>(
                                                <div className={'integration-bead'} title={`${q.name}-${q.value}`} onClick={()=>window.alert(`This playbook is integrated with ${q.name} (${q.value}).`)}>
                                                    {q.title[0].toUpperCase()}
                                                </div>
                                            ))
                                        }
                                    </div>
                                </h3>
                            </div>
                            <div className={'w100 inline-block text-right'}>
                                <div className={`post-update`} onClick={this.postUpdate} >
                                   Post Update
                                </div>
                            </div>
                            <div className="padded_sm">
                                <Checklist
                                    run={run}
                                    playbook={playbook}
                                />
                            </div>

                        </div>
                        <div className={'col-lg-2'}></div>
                        <div className="margin_top_xxl w100"></div>
                    </div>
                </div>
            </div>
        )
    }

    get enableDisableClass(){
        return (this.disableNewRunButton) ? 'disabled' : ''
    }

    get disableNewRunButton(){
        return this.state.playbookId==-1
    }

}

function mapStateToProps(state) {
    return {
        store: state
    };
}

export default connect(mapStateToProps, {
    addRun,
    removeRun,
    touchRuns,
    updateRun
})(Body)
