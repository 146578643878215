import React, {Component} from 'react'
import {connect} from 'react-redux';
import {storeValue} from '../../../../../services/runs'

class Subquestion extends Component {


    constructor(props) {
        super(props)
        this.onChange = this.onChange.bind(this)
        this.onSave = this.onSave.bind(this)
        this.state = {
            value: this.runState(props).value,
        }
    }

    runState(props){
        const {item, subquestion} = props
        const runState = item.runState.find((s)=>s.key === subquestion.name) || {}
        return runState
    }

    onChange(e){
        this.setState({ value: e.target.value})
    }

    async onSave(){
        const {item, subquestion} = this.props
        setTimeout(()=>{ this.setState({disabled: true })},0)
        console.log(item.id, subquestion.name, this.state.value)
        await storeValue(this.props, item.id, subquestion.name, this.state.value)
        setTimeout(()=>{ this.setState({disabled: false })},300)
    }

    render() {
        const {subquestion} = this.props
        const runState = this.runState(this.props)
        const actor = runState.actor || {}
        return (
            <div className={'question-item default-item subitem'}>
                {subquestion.question}
                {
                    this.state.value && actor.first &&
                    <div className="completed_by">
                        Completed by {actor.first} {actor.last}
                    </div>
                }
                <div>
                    <input
                        className="inline-block checkoff-question"
                        type={'text'}
                        onChange={this.onChange}
                        value={this.state.value}
                        disabled={this.state.disabled}
                    ></input>
                    <button
                        className="inline-block checkoff-question-save"
                        onClick={this.onSave}
                        disabled={this.state.disabled}
                    >
                        Save
                    </button>
                </div>
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        store: state
    };
}

export default connect(mapStateToProps, {
})(Subquestion)
