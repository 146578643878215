
export default (state = {}, action) => {
    let new_state = state;

    switch (action.type) {
        case 'add_respondent':
            new_state = {
                ...state,
                respondents: [...state.respondents, action.respondent]
            }
            break;
        case 'patch_respondent':
            new_state = {
                ...state,
                respondents: state.respondents.map((r)=>{
                    if(r.identifier === action.identifier){
                        return {
                            ...r,
                            ...action.patchObj,
                        }
                    } else {
                        return r
                    }
                })
            }
            break;

    }

    return new_state

}
