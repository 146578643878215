import React, {Component} from 'react'
import {connect} from 'react-redux';

class Number extends Component {


    get numberArray() {
        const answers = this.props.store.activeQuestion.answers
        if (answers[0]) {
            let arr = []

            for (let i = answers[0].min; i <= answers[0].max; i++) {
                arr.push(i)
            }

            return arr
        } else {
            return []
        }
    }


    render() {
        const answers = this.props.store.activeQuestion.answers
        const answer = answers[0]
        if(answer) {
            return (
                <div className={'w100 margin_top_sm padded_sm'}>
                    <div className={'w100 position-relative'}>
                        <img src={window.view_data.imgs.numeric_rating} className={'number-rating-number-centered'}/>
                    </div>

                </div>
            )
        } else {
            return (<div></div>)
        }
    }

}

function mapStateToProps(state) {
    return {
        store: state
    };
}

export default connect(mapStateToProps, {})(Number)
