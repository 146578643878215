import React from 'react';
import {Provider, connect} from 'react-redux';
import {createStore} from 'redux';
import rootReducer from './Runs/redux/reducer';
import Body from './Runs/Body';

const csfrToken = document.getElementsByName('csrf-token')[0];
const authenticationToken = csfrToken != null ? csfrToken.getAttribute('content') : '';

function mapStateToProps(state) {
    return {
        store: {
            ...state,
        },
        authenticationToken: authenticationToken
    }
}

const ConnectedBody = connect(mapStateToProps, {})(Body);

class Designer extends React.Component {

    constructor(props){
        super(props)
        const { questions } = props.runs_for ? JSON.parse(props.current_design_state.store) : {}
        const initial_state = {
            authenticationToken: authenticationToken,
            hi: 0, category: props.category || {},
            archived: props.archived,
            runsFor: props.runs_for,
            questions: (questions || []),
            users: (props.users || [])
                .sort((a, b) => a.last.localeCompare(b.last)),
            runs: (props.runs || [])
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((run)=>{
                    return {
                        ...run,
                        nextCheckoff: props.runs_for ? this.nextCheckoff(run, questions) : {},
                    }
                }),
            playbooks: (props.playbooks || [])
                .sort((a, b) => a.name.localeCompare(b.name))
        };
        this.store = createStore(rootReducer, initial_state);
    }

    nextCheckoff(run, questions){
        return questions
            .filter((q)=>q.status==='NEW')
            .filter((q)=>q.type==='item')
            .find((q,i)=>{
                const completed = (run.run_state.filter((s)=>(s.question_id == q.id && s.key === 'checkoff' && s.value === 'ON')) || [])
                return completed.length === 0
            })
    }


    render() {
        return (
            <Provider store={{...this.store}}>
                <ConnectedBody
                />
            </Provider>
        );
    }
}

export default Designer
