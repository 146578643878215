import React from 'react';
import {Provider, connect} from 'react-redux';
import {createStore} from 'redux';
import rootReducer from './Designer/redux/reducer';
import Body from './Designer/Body';
import defaultQuestion from "./utils/defaultQuestion";

const csfrToken = document.getElementsByName('csrf-token')[0];
const authenticationToken = csfrToken != null ? csfrToken.getAttribute('content') : '';

//dummy
const getItems = count =>
    Array.from({ length: count }, (v, k) => k).map(k => ({
        ...defaultQuestion,
        id: `item-${k}`,
        title: `Question ${k+1}`,
        index: k,
    }));
const questions = getItems(3)
//end dummy



function mapStateToProps(state) {
    return {
        store: state,
        authenticationToken: authenticationToken
    }
}

const ConnectedBody = connect(mapStateToProps, {})(Body);

class Designer extends React.Component {

    constructor(props){
        super(props)
        const initial_state = {
            hi: 0,
            questions, activeQuestion: questions[0],
            owner_id: props.owner_id,
            category: props.category,
            no_edit: false,
            filtered: 'NEW',
            ...this.previousStoredState,
            playbook: props.playbook,
            authenticationToken,
            current_design_state: props.current_design_state,
            design_states: props.design_states,
        };
        this.store = createStore(rootReducer, initial_state);
    }

    get previousStoredState(){
        try {
            return {
                ...JSON.parse(this.props.current_design_state.store),
                controlView: '',
            }
        } catch(err){
            console.log('Could not parse previous state')
            return {}
        }
    }

    render() {
        return (
            <Provider store={this.store}>
                <ConnectedBody
                    firstEntry={this.props.firstEntry}
                />
            </Provider>
        );
    }
}

export default Designer
