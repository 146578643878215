import React, {Component} from 'react'
import {connect} from 'react-redux';
import {removeRun} from '../redux/actions'
import {inactivateRun, activateRun} from "../../../services/runs";

class Body extends Component {


    async activateRun(id) {
        const resp = await activateRun(this.props, id)
        this.props.removeRun(resp.run)
    }

    async inactivateRun(id) {
        const resp = await inactivateRun(this.props, id)
        this.props.removeRun(resp.run)
    }

    render() {
        const { run } = this.props
        if(run.status === 'NEW'){
            return (
                <div className={'restore-playbook'} onClick={(e) => {
                    e.stopPropagation();
                    console.log(run)
                    this.inactivateRun(run.id)
                }}>
                    {'Archive'}
                </div>
            )
        } else {
            return (
                <div className={'restore-playbook'} onClick={(e) => {
                    e.stopPropagation();
                    console.log(run)
                    this.activateRun(run.id)
                }}>
                    {'Restore'}
                </div>
            )
        }

    }

}

function mapStateToProps(state) {
    return {
        store: state
    };
}

export default connect(mapStateToProps, {
    removeRun
})(Body)
