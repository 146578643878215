import React, {Component} from 'react'
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import {connect} from "react-redux";
import {
    setActiveQuestion,
    changeActiveQuestionValue,
    setActiveQuestionNext,
    setQuestion
} from "../Designer/redux/actions";
import CheckoffTextInput from "../Designer/CheckoffTextInput";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import WebHooker from "../components/WebHooker";
import Questioner from "../components/Questioner";
import ItemTypeHandler from "../components/ItemTypeToggler";

class IntegrationTile extends Component {

    constructor(props) {
        super(props)
        this.className = this.className.bind(this)
    }

    click() {
        this.props.setActiveQuestion(this.props.item)
    }

    className(snapshot) {
        return `tile ${snapshot.isDragging ? 'dragging' : ''} ${this.isActive ? 'active' : ''}`
    }

    get isActive() {
        const activeQuestion = this.props.store.activeQuestion || {}
        return activeQuestion.id === this.props.item.id;
    }

    render() {

        const {item, index, store} = this.props
        const {activeQuestion} = store
        const webhooks = (item.webhooks || [])
        const questions = (item.questions || [])
        const isActive = activeQuestion.id === item.id
        return (
            <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={provided.draggableProps.style}
                        onClick={this.click.bind(this)}
                        className={`${this.className(snapshot)} integration-tile`}
                    >
                        <div className="w100 margin_bottom_lg">
                            <ItemTypeHandler
                                item={item}
                            />
                        </div>

                        {
                            isActive &&
                            <div>

                                <div className={'archive-question float-right'} onClick={() => {
                                    const toStatus = activeQuestion.status === 'NEW' ? 'ARCHIVED' : 'NEW'
                                    this.props.changeActiveQuestionValue('status', toStatus)
                                    this.props.setActiveQuestionNext(activeQuestion, toStatus === 'NEW' ? "Restored to 'Current'" : "Question was archived")
                                }}>
                                    {activeQuestion.status === 'NEW' ? 'Archive' : 'Restore'}
                                </div>

                            </div>
                        }
                        {
                            isActive &&
                            <div>
                                <div>
                                    Type:
                                    <div>
                                        <select
                                            className={'integration-select'}
                                            value={this.props.item.name}
                                            onChange={(e) => this.props.setQuestion(this.props.item.id, 'name', e.target.value)}
                                        >
                                            <option>Please select</option>
                                            <option value={'Discord'}>Discord</option>
                                        </select>
                                    </div>
                                </div>
                                <div>
                                    Webhook url:
                                    <div>
                                        <input
                                            required={true}
                                            className={'question-question-name'}
                                            placeholder="Ex. https://discord/webhook/endpoint_here"
                                            value={this.props.item.value}
                                            onChange={(e) => this.props.setQuestion(this.props.item.id, 'value', e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            !isActive &&
                            <div>
                                <div>
                                    <div className={'question-question-title'}>
                                            {this.props.item.name}
                                    </div>
                                </div>
                                <div>
                                    <div className={'question-question-name'}>
                                            {this.props.item.value}
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                )}
            </Draggable>
        )
    }

}

function mapStateToProps(state) {
    return {
        store: state
    };
}

export default connect(mapStateToProps, {
    setActiveQuestion,
    setQuestion,
    changeActiveQuestionValue,
    setActiveQuestionNext,
})(IntegrationTile)
