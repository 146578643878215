import proposals_only from './globals/proposals_only'
import standard from './globals/standard'

export default class {

    static config(){

        const type = window.view_data.global_values.server_type

        switch(type){
            case 'standard':
                return standard
            case 'proposals_only':
                return proposals_only
            default:
                return standard
        }

    }

    static playbook_navigation(playbookId){
        const config = this.config()

        return config.playbook_navigation.replace('{playbookId}', playbookId)
    }

    static playbook_builder_navigation(playbookId){
        const config = this.config()

        return config.playbook_builder_navigation.replace('{playbookId}', playbookId)
    }
}
