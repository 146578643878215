import React, {Component} from 'react'
import {connect} from 'react-redux';
import {changeActiveQuestionValue, setActiveQuestion, setActiveQuestionNext} from "../../redux/actions";
import TextInput from "../../TextInput";
import Select from "../../Select";
import AnswerControl from "../../AnswerControl/AnswerControl";
import FooterCtas from "./QuestionControls/FooterCtas";
import AddableTextInput from "../../../AddableTextInput";

class QuestionControls extends Component {

    render() {
        const {activeQuestion} = this.props.store
        return (
            <div className={'w100'}>
                <div className={'archive-question float-right'} onClick={() => {
                    const toStatus = activeQuestion.status === 'NEW' ? 'ARCHIVED' : 'NEW'
                    this.props.changeActiveQuestionValue('status', toStatus)
                    this.props.setActiveQuestionNext(activeQuestion, toStatus === 'NEW' ? "Restored to 'Current'" : "Question was archived")
                }}>
                    {activeQuestion.status === 'NEW' ? 'Archive' : 'Restore'}
                </div>
                <Select
                    label={'Question Type'}
                    field={'question_type'}
                    options={[
                        {value: 'informational', displayText: 'Informational'},
                        {value: 'multi-button', displayText: 'Multi-button'},
                        {value: 'two-answer', displayText: 'Two answer'},
                        {value: 'long-answer', displayText: 'Open-ended response'},
                        {value: 'numeric-rating', displayText: 'Numeric rating'},
                        {value: 'number', displayText: 'Simple number input'},
                        {value: 'sign', displayText: 'Signature screen'},
                        {value: 'confirmation', displayText: 'Confirmation'},
                    ]}
                />
                <TextInput
                    label={'Title'}
                    field={'title'}
                    className={'title-input-box'}
                />
                <TextInput
                    textarea={true}
                    label={'Content'}
                    field={'question_text'}
                />

                <AnswerControl
                    label={'Answers'}
                    field={'title'}
                    className={'title-input-box'}
                />

                <div className="margin_top_md">
                    <AddableTextInput
                        textarea={true}
                        label={'Deployment Note'}
                        field={'note'}
                        className={'title-input-box'}
                    />
                </div>
                <FooterCtas/>
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        store: state
    };
}

export default connect(mapStateToProps, {
    changeActiveQuestionValue,
    setActiveQuestion,
    setActiveQuestionNext,
})(QuestionControls)
