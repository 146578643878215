import React, {Component} from 'react'
import {connect} from "react-redux";
import {setQuestion} from "../Designer/redux/actions";
import WebHook from "./Webhook";

class WebHooker extends Component {

    constructor(props) {
        super(props)
        this.state = {}
        this.addWebhook = this.addWebhook.bind(this)
        this.toggleExpand = this.toggleExpand.bind(this)
    }

    addWebhook(){
        const {item} = this.props
        const webhooks = this.props.item.webhooks || []
        this.props.setQuestion(item.id, 'webhooks', [...webhooks, { id: new Date().getTime(), type: 'POST', route: '' }])
    }

    toggleExpand(){
        const {expanded} = this.state
        this.setState({expanded: !expanded})
    }

    render() {

        const {item, index, store} = this.props
        const {expanded} = this.state
        const webhooks = (item.webhooks||[])

        return (
            <div className="display-box">
                <div className="section-expander" onClick={this.toggleExpand}>
                    Webhooks <div className={'expand-cta'}>{`${expanded ? 'close (x)' : '(edit)️'}`}</div>
                </div>
                {
                    !expanded &&
                    <div onClick={this.toggleExpand}>
                        {
                            webhooks.map((w, i)=>{
                                return (
                                    <div className={'read-only-webhooks'} key={`webhook-detail-${i}`}>
                                        {w.type} – {w.route}
                                    </div>
                                )
                            })
                        }
                    </div>
                }
                {
                    expanded &&
                    <div>
                        {
                            webhooks.map((webhook, i)=>{
                                return (
                                    <WebHook
                                        item={item}
                                        webhook={webhook}
                                        i={i}
                                    />
                                )
                            })
                        }

                        <div className="w100 text-right margin_top_md">
                            <div className='add-webhook inline-block' onClick={this.addWebhook}>
                                Add Webhook
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        store: state
    };
}

export default connect(mapStateToProps, {
    setQuestion,
})(WebHooker)
