import React, {Component} from 'react'
import {connect} from 'react-redux';
import {changeActiveQuestionValue, updateAnswer} from "./../redux/actions";
import defaultAnswer from "../../utils/defaultAnswer";
import TextInput from "../TextInput";

class OpenEndedControl extends Component {

    componentDidMount() {

    }

    render() {

        return (
            <div className={'w100'}>
                <TextInput
                    label={'Placeholder'}
                    field={'placeholder'}
                    className={'title-input-box'}
                />

            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        store: state
    };
}

export default connect(mapStateToProps, {
    changeActiveQuestionValue,
    updateAnswer,
})(OpenEndedControl)
