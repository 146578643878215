import React, {Component} from 'react'
import {connect} from 'react-redux';
import { patchState } from './redux/actions'

class UtilityBar extends Component {

    get viewShowing(){
        switch(this.props.store.controlView){
            case 'version_control':
                return 'Version Control'
            default:
                return 'Question Editor'
        }
    }


    constructor(props) {
        super(props)
        this.state = {
            showMenu: false,
        }
    }

    render() {
        const {store} = this.props
        return (
            <div>
            <div className="designer-menu no-select cursor_pointer" onClick={() => {
                console.log('open')
                this.setState({showMenu: true})
            }}>
                {`${this.viewShowing}`}
            </div>
                {
                    this.state.showMenu &&
                    <div className={'menu-drawer'}>
                        <div className={'close'} onClick={(e) => {
                            e.preventDefault()
                            console.log('close');
                            this.setState({showMenu: false})
                        }}>
                            Close (x)
                        </div>
                        <div className={'item'} onClick={() => {
                            this.props.patchState({ controlView: '' })
                            this.setState({ showMenu: false })
                        }}>
                            Question Editor
                        </div>
                        <div className={'item'} onClick={() => {
                            this.props.patchState({ controlView: 'version_control' })
                            this.setState({ showMenu: false })
                        }}>
                            Version Control
                        </div>
                    </div>
                }
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        store: state
    };
}

export default connect(mapStateToProps, {
    patchState,
})(UtilityBar)
