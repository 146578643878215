import React, {Component} from 'react'
import {connect} from 'react-redux';
import {changeActiveQuestionValue, } from "../../../redux/actions";
import Toggle from "../../../Toggle";

class FooterCtas extends Component {

    render() {
        const { activeQuestion } = this.props.store
        return (
            <div className={'w100'}>
                <hr/>
                <div className={'w100'}>
                    <span className={'toggle-label'}>Show back button</span>
                    <Toggle
                        field={'has_back_button'}
                        className={'float-right'}
                    />
                </div>
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        store: state
    };
}

export default connect(mapStateToProps, {
    changeActiveQuestionValue,
})(FooterCtas)
