

export const defaults = (config, type)=>{
    const typeConfig = config[type]
    return {
        proposal_type: type,
        duration: typeConfig.duration.defaultValue,
        zone_count: typeConfig.zone_count.defaultValue,
        patient_count: typeConfig.patient_count.defaultValue,
        language_count: typeConfig.language_count.defaultValue,
        site_count: typeConfig.site_count.defaultValue,
        training: typeConfig.training.defaultValue,
        integration_count: typeConfig.integration_count.defaultValue,
        coordination_hours: typeConfig.coordination_hours.defaultValue,
        support_hrs: typeConfig.support_hrs.defaultValue,
    }
}
