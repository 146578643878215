// var config = require('../config.json');
// import host from './host'

export const createRun = async (props, run)=>{
    const response = await fetch(`/runs/create`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRF-Token': props.authenticationToken || props.store.authenticationToken,

        },
        body: JSON.stringify({
            ...run
        }),
    })

    return await response.json()
}

export const patchRun = async (props, id, run)=>{
    const response = await fetch(`/runs/${id}/patch`, {
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRF-Token': props.authenticationToken || props.store.authenticationToken,

        },
        body: JSON.stringify({
            ...run
        }),
    })

    return await response.json()
}

export const postUpdate = async (props, run) => {
    const response = await fetch(`/runs/${run?.id}/post_update`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRF-Token': props.authenticationToken || props.store.authenticationToken,

        },
        body: JSON.stringify({
            ...run
        }),
    })

    return await response.json() 
}

export const activateRun = async (props, id)=>{
    const response = await fetch(`/runs/${id}/activate`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRF-Token': props.authenticationToken || props.store.authenticationToken,
        },
        body: JSON.stringify({}),
    })

    return await response.json()
}

export const inactivateRun = async (props, id)=>{
    const response = await fetch(`/runs/${id}/inactivate`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRF-Token': props.authenticationToken || props.store.authenticationToken,

        },
        body: JSON.stringify({ }),
    })

    return await response.json()
}

export const checkoff = async (props, questionId, checked)=>{
    const id = props.store.run.id
    const response = await fetch(`/runs/${id}/checkoff`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRF-Token': props.authenticationToken || props.store.authenticationToken,

        },
        body: JSON.stringify({
            playbook_id: props.store.playbook.id,
            checkbox_state: checked ? 'ON' : 'OFF',
            question_id: questionId,
        }),
    })

    return await response.json()
}

export const storeValue = async (props, questionId, key, value)=>{
    const id = props.store.run.id
    const response = await fetch(`/runs/${id}/store_value`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRF-Token': props.authenticationToken || props.store.authenticationToken,

        },
        body: JSON.stringify({
            playbook_id: props.store.playbook.id,
            question_id: questionId,
            key,
            value,
        }),
    })

    return await response.json()
}
