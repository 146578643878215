import React, {Component} from 'react'
import {connect} from 'react-redux';
import {addCategory, updateCategory, removeCategory, touchCategories} from './redux/actions'
import UserNav from "../../UserNav";
import {createCategory, patchCategory} from "../../services/category";
import StopTypingInput from '../../shared/StopTypingInput'
import ArchiveAndRestore from './components/ArchiveAndRestore'

class Body extends Component {

    constructor(props) {
        super(props)
        this.addBlankCategory = this.addBlankCategory.bind(this)
        this.searchFilter = this.searchFilter.bind(this)
        this.state = {
            search: ''
        }
    }

    searchFilter(p) {
        if (this.state.search) {
            return `${p.name} ${p.id}`.toLowerCase().includes(this.state.search.toLowerCase())
        } else {
            return p
        }
    }

    async addBlankCategory() {
        const blankCategory = {name: 'New Category'}
        const resp = await createCategory(this.props, blankCategory)
        console.log(resp.category)
        this.props.addCategory(resp.category)
    }

    async updateCategory(id, text) {
        const fields = {name: text}
        const resp = await patchCategory(this.props, id, fields)
        this.props.updateCategory(resp.category)
    }

    render() {
        return (
            <div className='whole-body'>
                <UserNav/>
                <div>
                    <div className={'row no-margin'}>
                        <img src={window.view_data.imgs.nav_banner_sm} className={'nav-banner'}/>
                        <div className={'col-lg-2'}></div>
                        <div className={'col-lg-8 text-left margin_top_md'}>

                            <div className={'margin_top_sm margin_bottom_sm w100 text-left bread-crumb-js'}>
                                <a href={`/portal`}>Playbook Categories</a>
                            </div>
                            <hr/>

                            {
                                !this.props.store.archived &&
                                <div className={'w100'}>
                                    <small><a href={'/portal/archived'} className={'float-right'}>See Archived</a></small>
                                </div>
                            }
                            {
                                this.props.store.archived &&
                                <div className={'w100'}>
                                    <small><a href={'/portal'} className={'float-right'}>See Current</a></small>
                                </div>
                            }
                            <div className="padded_xs">
                                <input
                                    type={'text'}
                                    placeholder={'Search category titles'}
                                    className={'margin_top_lg margin_bottom_md w100'}
                                    onChange={(e) => {
                                        this.setState({search: e.target.value})
                                    }}

                                />
                            </div>


                            {
                                this.props.store.categories
                                    .filter(this.searchFilter)
                                    .map((category, index) => {
                                        return (
                                            <a onClick={() => window.location = `/category/${category.id}`}
                                               className={'no-padding no-underline card-tile-link'} key={index}>
                                                <div
                                                    className={`card text-center category-tile no_select text-left ${category.selected ? 'selected' : ''}`}>
                                                    <ArchiveAndRestore
                                                        category={category}
                                                        authenticationToken={this.props.authenticationToken}
                                                    />
                                                    <StopTypingInput
                                                        className={'w100 category-name'}
                                                        id={category.id}
                                                        timeForStop={1500}
                                                        onFocus={() => this.props.updateCategory({id: category.id})}
                                                        value={category.name}
                                                        defaultValue={category.name}
                                                        onTextStop={(text) => {
                                                            this.updateCategory(category.id, text)
                                                        }}
                                                    />
                                                    <div><img src={window.view_data.imgs.access}
                                                              className={'access-icon float-left'}/></div>
                                                </div>
                                            </a>
                                        )
                                    })
                            }
                            <div>

                            <div className="cdp-btn margin_bottom_xl" onClick={this.addBlankCategory}>
                                (+) New Category
                            </div>
                            </div>
                        </div>
                        <div className={'col-lg-2'}></div>
                    </div>
                </div>
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        store: state
    };
}

export default connect(mapStateToProps, {
    addCategory,
    updateCategory,
    removeCategory,
    touchCategories
})(Body)
