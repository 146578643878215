export default class DeploySampleData {


    static get data(){
        return [
            {
                identifier: '3045001',
                sent: '11/30/2020',
                completionStatus: 'Completed',
                status: 'active',
            }, {
                identifier: '3045002',
                sent: '11/29/2020',
                completionStatus: 'Unstarted',
                status: 'active',
            }, {
                identifier: '3045003',
                sent: '11/28/2020',
                completionStatus: 'Completed',
                status: 'active',
            }, {
                identifier: '3045004',
                sent: '11/30/2020',
                completionStatus: 'Completed',
                status: 'active',
            }, {
                identifier: '3045005',
                sent: '11/30/2020',
                completionStatus: 'Unstarted',
                status: 'active',
            }, {
                identifier: '3045006',
                sent: '11/29/2020',
                completionStatus: 'Abandoned',
                status: 'active',
            }, {
                identifier: '3045007',
                sent: '11/30/2020',
                completionStatus: 'Unstarted',
                status: 'active',
            }, {
                identifier: '3045008',
                sent: '11/30/2020',
                completionStatus: 'Unstarted',
                status: 'active',
            }
        ]
    }

}
