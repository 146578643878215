import {saveDesignerState} from "../../../services/designer";

export default class ServerSaver {

    constructor(patchState){
        this.patchState = patchState
        this.lastSaveMs = null
    }


    save(props){
        // const propsLastStateMs = props.store.lastSaveMs
        const store = props.store
        if(!store.no_edit) { //when no_edit is truthy, we cannot save state, message appears in 'alert-bar'
            const saveFailedMs = props.store.saveFailedMs
            const lastSaveMs = new Date().getTime()
            this.lastSaveMs = lastSaveMs

            setTimeout(async () => {
                if (this.lastSaveMs === lastSaveMs) {
                    const resp = await saveDesignerState(store)
                    const ms = new Date().getTime()

                    if (resp) {
                        console.log(`Save succeeded at ${ms}`)
                    } else {
                        console.log(`Save failed at ${ms}`)
                    }


                    if (resp && saveFailedMs) {
                        this.patchState({saveFailedMs: null})
                    } else if (!resp && !saveFailedMs) {
                        this.patchState({saveFailedMs: ms})
                    }
                }
            }, 2000)
        }

    }

}
