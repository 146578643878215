import React, {Component} from 'react'
import {connect} from 'react-redux';
import QuestionControls from "./QuestionControlType/QuestionControls";

class QuestionControlType extends Component {


    render() {
        const {store} = this.props
        return (
            <div>
                <div className={'card-padding'}>
                    <QuestionControls

                    />
                </div>
                <div className={'card-spacing'}>

                </div>
                <div className={'question-control-card-footer'}>
                    <div className="w100 text-center">
                        <div className='cdp-btn'
                             onClick={() => window.location = `/playbook/${store.playbook.id}/proposal`}>
                            Request Deployment <div>(Start Testing)</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        store: state
    };
}

export default connect(mapStateToProps, {
})(QuestionControlType)
