import React, {Component} from 'react'
import {connect} from 'react-redux';
import ProposalListing from './universal_list/ProposalListing'
import DeploymentListing from './universal_list/DeploymentListing'
import AuditListing from './universal_list/AuditListing'
const csfrToken = document.getElementsByName('csrf-token')[0];
const authenticationToken = csfrToken != null ? csfrToken.getAttribute('content') : '';

class UniversalList extends Component {

    constructor(props) {
        super(props)
        this.searchFilter = this.searchFilter.bind(this)
        this.state = {
            search: ''
        }
    }

    searchFilter(p) {
        if (this.state.search) {
            return `${p.name} ${p.id}`.toLowerCase().includes(this.state.search.toLowerCase())
        } else {
            return p
        }
    }

    render() {
        console.log(this.props)
        return (
            <div className={'w100'}>
                <input
                    type={'text'}
                    placeholder={`Search ${this.props.typeDisplayName}`}
                    className={'w100 margin_top_lg margin_bottom_md'}
                    onChange={(e) => {
                        this.setState({search: e.target.value})
                    }}

                />
                {
                    (this.props[this.props.type] || [])
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .filter(this.searchFilter)
                        .map((item, index) => {
                            if(this.props.type === 'proposals') {
                                return (
                                    <ProposalListing
                                        proposal={item}
                                        index={index}
                                        key={index}
                                        authenticationToken={authenticationToken}

                                    />
                                )
                            } else if (this.props.type === 'deployments') {
                                return (
                                    <DeploymentListing
                                        deployment={item}
                                        index={index}
                                        key={index}
                                        authenticationToken={authenticationToken}

                                    />
                                )
                            } else if (this.props.type === 'audits') {
                                return (
                                    <AuditListing
                                        audit={item}
                                        index={index}
                                        key={index}
                                        authenticationToken={authenticationToken}

                                    />
                                )
                            }
                        })
                }
            </div>
        )
    }

}

export default UniversalList
