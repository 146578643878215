import React, {Component} from 'react'
import {connect} from "react-redux";
import {setQuestion} from "../../Designer/redux/actions";

class Params extends Component {

    constructor(props) {
        super(props)
        this.state = {
            keyvals: this.getInitial(props),
        }
    }

    getInitial(props){
        let keyvals = []
        const params = props.webhook.params||{}
        for(let property in params){
            keyvals = [...keyvals, { key: property, val: params[property]}]
        }
        return keyvals
    }

    updateProperty(id, key, value){
        const webhooks = this.props.item.webhooks || []
        const {item} = this.props

        const newWebhooks = webhooks.map((w)=>{
            if(w.id === id){
                return {...w,[key]:value}
            } else {
                return w
            }
        })

        this.props.setQuestion(item.id, 'webhooks', newWebhooks)
    }

    updateParams(){
        setTimeout(()=>{
            console.log(`Updated ${new Date().getTime()}`)
            const {item, webhook, i} = this.props
            let params = {}
            this.state.keyvals.forEach((kv)=>{
                params[kv.key] = kv.val
            })
            this.updateProperty(webhook.id, 'params', params)
        },0)
    }

    removePair(index){
        const keyvals = this.state.keyvals.filter((kv,i)=>i!==index)
        this.setState({ keyvals })
        this.updateParams()
    }

    updatePair(property, e,index){
        const keyvals = this.state.keyvals.map((kv,i)=>{
            if(i===index){
                return {
                    ...kv,
                    [property]: e.target.value
                }
            } else {
                return kv
            }
        })
        this.setState({ keyvals })
        this.updateParams()
    }


    render() {
        const {item, webhook, i} = this.props
        return (
            <div className="w100 property-builder">
                <div className="inline-block property-title">PARAMS</div>
                <div className="inline-block float-right property-add" onClick={()=>this.setState({
                    keyvals: [...this.state.keyvals, {key: '', value: ''}]
                })}>
                    Add (+)
                </div>
                <hr class="property-hr"/>
                {
                    this.state.keyvals.map((kv, i)=>{
                        return (
                            <div className="property-pairs" key={`property-pair-params-${i}`}>
                                <div className="property-pair">
                                    Key:&nbsp;
                                    <input
                                        value={kv.key}
                                        onChange={(e)=>this.updatePair('key',e,i)}
                                    />&nbsp;
                                    Value:&nbsp;
                                    <input
                                        value={kv.val}
                                        onChange={(e)=>this.updatePair('val',e,i)}
                                    />&nbsp;
                                    <div
                                        className="float-right remove-pair"
                                        onClick={()=>this.removePair(i)}
                                    >
                                        x
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }

            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        store: state
    };
}

export default connect(mapStateToProps, {
    setQuestion,
})(Params)
