// var config = require('../config.json');
// import host from './host'

export const createPlaybook = async (props, playbook)=>{
    const response = await fetch(`/category/${props.store.category.id}/playbook`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRF-Token': props.authenticationToken || props.store.authenticationToken,

        },
        body: JSON.stringify({
            ...playbook
        }),
    })

    return await response.json()
}
export const patchPlaybook = async (props, id, playbook)=>{
    const response = await fetch(`/category/${props.store.category.id}/playbook/${id}/patch`, {
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRF-Token': props.authenticationToken || props.store.authenticationToken,

        },
        body: JSON.stringify({
            ...playbook
        }),
    })

    return await response.json()
}
export const activatePlaybook = async (props, id)=>{
    const response = await fetch(`/category/${props.store.category.id}/playbook/${id}/activate`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRF-Token': props.authenticationToken || props.store.authenticationToken,

        },
        body: JSON.stringify({ }),
    })

    return await response.json()
}
export const inactivatePlaybook = async (props, id)=>{
    const response = await fetch(`/category/${props.store.category.id}/playbook/${id}/inactivate`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRF-Token': props.authenticationToken || props.store.authenticationToken,

        },
        body: JSON.stringify({ }),
    })

    return await response.json()
}
