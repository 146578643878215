import React, {Component} from 'react'
import {connect} from 'react-redux';
import Run from "./Run";

class RunsList extends Component {


    constructor(props) {
        super(props)
        this.searchFilter = this.searchFilter.bind(this)
        this.byTypeFilter = this.byTypeFilter.bind(this)
    }
    searchFilter(r) {
        if (this.props.search) {
            return `${r.name} ${r.id}`.toLowerCase().includes(this.props.search.toLowerCase())
        } else {
            return true
        }
    }

    byTypeFilter(r){
        const byType = Number(this.props.byType)
        if(byType !== -1){
            return r.playbook_id === byType
        } else {
            return true
        }

    }

    render() {
        const {run, index, store} = this.props
        return (
            <div className={'w100'}>

                {
                    this.props.store.runs
                        .filter(this.byTypeFilter)
                        .filter(this.searchFilter)
                        .map((run, index) => {
                            return (
                                <Run
                                    run={run}
                                    index={index}
                                />
                            )
                        })
                }
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        store: state
    };
}

export default connect(mapStateToProps, {
})(RunsList)
