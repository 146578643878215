import React, {Component} from 'react'
import {connect} from 'react-redux';
import StopTypingInput from "../shared/StopTypingInput";
import {patchProposal} from "../services/proposal";
import ArchiveAndRestoreButton from "./ProposalListing/ArchiveAndRestoreButton";
import ActionButton from "./ProposalListing/ActionButton";

class ProposalListing extends Component {

    constructor(props) {
        super(props)
        this.state = {
            proposal: props.proposal,
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.proposal.id !== this.props.proposal.id) {
            this.setState({proposal: this.props.proposal})
        }
    }

    render() {
        const {proposal} = this.state
        if (proposal) {
            return (
                <div className="proposal-listing no-select" key={this.props.index}
                     onClick={() => this.setState({expanded: !this.state.expanded})}>
                    <div className="w100">
                        <StopTypingInput
                            id={proposal.id}
                            // onFocus={()=>this.props.updatePlaybook({ id: playbook.id })}
                            className={'w100 category-name'}
                            value={proposal.name}
                            timeForStop={400}
                            defaultValue={proposal.name}
                            onTextStop={async (text) => {
                                const newProposal = {
                                    ...this.state.proposal,
                                    name: text ? text : '--',
                                }

                                patchProposal(newProposal, this.props)

                                this.setState({
                                    proposal: newProposal
                                })
                            }}
                        />
                        {/*<ActionButton*/}
                            {/*href={`/proposal/duplicate/${this.state.proposal.id}`}*/}
                            {/*text={'Duplicate'}*/}
                        {/*/>*/}
                        <ArchiveAndRestoreButton
                            proposal={this.state.proposal}
                            authenticationToken={this.props.authenticationToken}
                            onChange={(p) => this.setState({
                                proposal: null
                            })}
                        />
                        {
                            this.state.expanded &&
                            <div className={'padded_lg can-select'}>
                                <hr/>
                                <div className={'row'}>
                                    <div className="col-md-6" onClick={(e) => e.stopPropagation()}>
                                        <div className={''}>
                                            <div>Proposal Type: <b>{proposal.proposal_type === 'sdc' ? 'e-Diary / ePRO' : 'One-time Questionnaire'}</b></div>
                                            {
                                                proposal.proposal_type === 'sdc' &&
                                                <div>
                                                    <div>Cost: <span className={'prefix-label'}>$</span>
                                                        <span className={'accent'}>{ proposal.proposal_type === 'sdc' ? `${proposal.grand_total}/mo` : `${proposal.grand_total}`}</span> <span
                                                            className={'suffix-label'}></span>

                                                    </div>


                                                    <div class="proposal-details-block">
                                                        <div>Number of languages: <b>{proposal.language_count}</b></div>
                                                        <div>Patient count: <b>{proposal.patient_count}</b></div>
                                                        <div>Zone count: <b>{proposal.zone_count}</b></div>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                proposal.proposal_type !== 'sdc' &&
                                                <div>
                                                    <div>Cost: <span className={'prefix-label'}>$</span>
                                                        <span className={'accent'}>{proposal.monthly_payment}</span>
                                                        <span
                                                            className={'suffix-label'}>/mo</span>
                                                    </div>
                                                    <div>
                                                        {proposal.duration} mos contract,
                                                    </div>
                                                    <div>
                                                        ${proposal.total_margin} reseller total margin
                                                    </div>
                                                    <div>
                                                        ${proposal.monthly_margin} reseller monthly margin
                                                    </div>
                                                    <div>
                                                        ${proposal.monthly_post_contract_payment} (optional)
                                                        month-to-month
                                                        afterwards
                                                    </div>
                                                    <div>
                                                        ${proposal.monthly_post_contract_margin} (reseller margin)
                                                        month-to-month
                                                        afterwards
                                                    </div>

                                                    <div className="proposal-details-block">
                                                        <div>Number of languages: <b>{proposal.language_count}</b></div>
                                                        <div>Integration count: <b>{proposal.integration_count}</b>
                                                        </div>
                                                        <div>Coordination hours: <b>{proposal.coordination_hours}</b>
                                                        </div>
                                                        <div>Site count: <b>{proposal.site_count}</b></div>
                                                        <div>Zone count: <b>{proposal.zone_count}</b></div>
                                                        <div>Support hours: <b>{proposal.support_hrs}</b></div>
                                                        <div>Training: <b>{proposal.training}</b></div>
                                                    </div>


                                                </div>
                                            }
                                            {
                                                (proposal.line_items || []).length > 0 &&
                                                <div className={'margin_top_md'}>
                                                    Line items:
                                                    {
                                                        proposal.line_items.map((lineItem) => {
                                                            return (
                                                                <div className={'line-item'}>
                                                                    {lineItem.id}: {lineItem.name}
                                                                </div>
                                                            )
                                                        })
                                                    }

                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div>
                                            Category: {proposal.category.name}
                                        </div>
                                        <div>
                                            Playbook: {proposal.playbook.name}
                                        </div>
                                        <div>
                                            Owner: {proposal.owner.first} {proposal.owner.last}
                                        </div>
                                        <div>
                                            Organization: {proposal.organization.name}
                                        </div>
                                        {/*<div className={'margin_top_lg'}>*/}
                                            {/*{*/}
                                                {/*proposal.pdf_exists &&*/}
                                                {/*<a target={'_blank'} className={'no-padding'} href={`/proposal/download/${proposal.id}`}>Download*/}
                                                    {/*PDF</a>*/}
                                            {/*}*/}
                                            {/*{*/}
                                                {/*!proposal.pdf_exists &&*/}
                                                {/*<i>*/}
                                                    {/*PDF Pending*/}
                                                {/*</i>*/}
                                            {/*}*/}
                                        {/*</div>*/}

                                    </div>
                                </div>

                            </div>
                        }

                    </div>
                </div>
            )
        } else {
            return (<div></div>)
        }
    }

}

export default ProposalListing
