import React, {Component} from 'react'
import {connect} from 'react-redux';

class UserNav extends Component {

    render() {
        return (
            <div className={'nav text-right padding_top_sm nav-fonts'}>
                <a href="/runs">
                    <div className="main-corner-nav">
                        <div className="logo_div">
                            <img src={window.view_data.imgs.logo_andalemono} className="logo-main"/>
                        </div>
                    </div>
                </a>
                <div className={'w100 nav-left-margin text-left'}>
                    <a href='/runs' className='margin_left_md'>All Runs</a>
                    <a href='/portal' className='margin_left_xl'>Playbooks</a>
                    <a href={'/logout'} className={'logout'}>Logout</a>
                </div>
            </div>
        )
    }

}

export default UserNav
