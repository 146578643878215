import React, {Component} from 'react'
import UserNav from "../UserNav";
import _ from 'lodash'

const ReactMarkdown = require('react-markdown')
import marked from "marked"

const input = ''

class Documentation extends Component {

    constructor(props) {
        super(props)
        this.state = {contents: props.contents}
    }


    render() {
        return (
            <div className='whole-body'>
                <UserNav/>
                <div>
                    <div className={'row no-margin'}>
                        <img src={window.view_data.imgs.nav_banner_sm} className={'nav-banner'}/>
                        <div className={'col-sm-1 col-lg-2 text-left'}></div>
                        <div className={'col-sm-10 col-lg-8 text-left margin_top_xl'}>
                            <div className={'row no-margin'}>
                                <div className={'col-lg-3 text-left'}>
                                    <div>
                                        <a href={'/documentation'}>Home</a>
                                    </div>
                                    <div>
                                        <a href={'/documentation/how_tos'}>How To's</a>
                                    </div>
                                    <div>
                                        <a href={'/documentation/faqs'}>FAQs</a>
                                    </div>
                                    <div>
                                        <a href={'/documentation/requesting_support'}>Requesting Support</a>
                                    </div>
                                </div>
                                <div className={'col-lg-7 text-left'}>
                                    <ReactMarkdown
                                        source={this.state.contents}
                                        renderers={{link: props => { //open external links in new tab
                                            return props.href.includes('http') ? (<a href={props.href} target="_blank">{props.children}</a>) : (<a href={props.href}>{props.children}</a>)
                                        },
                                        image: props => {
                                            console.log(props)
                                            if(props.src.includes('http')){
                                                return (<img {...props} style={{maxWidth: '100%'}} className={props.title} />)
                                            } else {
                                                return (<img {...props} src={_.get(window, props.src)} style={{maxWidth: '100%'}} className={props.title} />)
                                            }
                                        }
                                        }}
                                     />
                                </div>
                            </div>
                        </div>
                        <div className={'col-sm-1 col-lg-2'}></div>


                    </div>
                </div>
            </div>
        )
    }

}


export default Documentation

