import React, {Component} from 'react'
import {connect} from 'react-redux';
import {addPlaybook, removePlaybook, touchPlaybooks, updatePlaybook} from './redux/actions'
import UserNav from "../../UserNav";
import {createPlaybook} from "../../services/playbook";
import Playbook from './components/Playbook'

class Body extends Component {

    constructor(props) {
        super(props)
        this.addBlankPlaybook = this.addBlankPlaybook.bind(this)
        this.searchFilter = this.searchFilter.bind(this)
        this.state = {
            search: ''
        }
    }

    searchFilter(p) {
        if (this.state.search) {
            return `${p.name} ${p.id}`.toLowerCase().includes(this.state.search.toLowerCase())
        } else {
            return p
        }
    }

    async addBlankPlaybook() {
        const blankPlaybook = {name: 'New Playbook'}
        const resp = await createPlaybook(this.props, blankPlaybook)
        console.log(resp.playbook)
        this.props.addPlaybook(resp.playbook)
    }

    render() {
        const {category} = this.props.store
        return (
            <div className='whole-body category-body'>
                <UserNav/>

                <div>
                    <div className={'row no-margin'}>
                        <img src={window.view_data.imgs.nav_banner_sm} className={'nav-banner'}/>
                        <div className={'col-lg-2'}></div>
                        <div className={'col-lg-8 margin_top_md'}>

                            <div className={'margin_top_sm margin_bottom_sm w100 text-left bread-crumb-js'}>
                                <a href={`/portal`}>Categories</a> > <a href={`/category/${category.id}`}>{category.name} –
                                Playbooks</a>
                            </div>
                            <hr/>


                            {
                                !this.props.store.archived &&
                                <div className={'w100'}>
                                    <small><a href={`/category/${this.props.store.category.id}/archived`}
                                              className={'float-right'}>Archived</a></small>
                                </div>
                            }
                            {
                                this.props.store.archived &&
                                <div className={'w100'}>
                                    <small><a href={`/category/${this.props.store.category.id}`}
                                              className={'float-right'}>Current</a></small>
                                </div>
                            }

                            <input
                                type={'text'}
                                placeholder={'Search playbook titles'}
                                className={'w100 margin_top_md'}
                                onChange={(e) => {
                                    this.setState({search: e.target.value})
                                }}

                            />

                            <div className={'w100'}>

                                {
                                    this.props.store.playbooks
                                        .filter(this.searchFilter)
                                        .map((playbook, index) => {
                                            return (
                                                <Playbook
                                                    playbook={playbook}
                                                    index={index}
                                                />
                                            )
                                        })
                                }
                            </div>
                            <div>
                            <div className="cdp-btn margin_bottom_xl" onClick={this.addBlankPlaybook}>
                                (+) New Playbook
                            </div>
                            </div>
                        </div>
                        <div className={'col-lg-2'}></div>
                    </div>
                </div>
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        store: state
    };
}

export default connect(mapStateToProps, {
    addPlaybook,
    removePlaybook,
    touchPlaybooks,
    updatePlaybook
})(Body)
