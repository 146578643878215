import prepStoreForSaving from "../utils/prepStoreForSaving";

export const saveDesignerState = async (store) => {
    try {


        const response = await fetch(`/designer/save`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                'X-CSRF-Token': store.authenticationToken,
            },
            body: JSON.stringify({
                owner_id: store.owner_id,
                playbook_id: store.playbook.id,
                category_id: store.category.id,
                createdAt: new Date().getTime().toString(),
                store: prepStoreForSaving(store),
            }),
        })

        return await response.json()

    } catch (err) {
        console.log(err)
        return null
    }
}

export const restoreVersion = async (store, designStateId) => {
    try {

        const response = await fetch(`/designer/restore`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                'X-CSRF-Token': store.authenticationToken,
            },
            body: JSON.stringify({
                playbook_id: store.playbook.id,
                category_id: store.category.id,
                design_state_id: designStateId,
            }),
        })

        return await response.json()

    } catch (err) {
        console.log(err)
        return null
    }
}

