import React, {Component} from 'react'
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import {connect} from "react-redux";
import {setActiveQuestion, changeActiveQuestionValue, setActiveQuestionNext, setQuestion} from "../Designer/redux/actions";
import CheckoffTextInput from "../Designer/CheckoffTextInput";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import WebHooker from "../components/WebHooker";
import Questioner from "../components/Questioner";
import ItemTypeHandler from "../components/ItemTypeToggler";

class Tile extends Component {

    constructor(props) {
        super(props)
        this.className = this.className.bind(this)
    }

    click(){
        this.props.setActiveQuestion(this.props.item)
    }

    className(snapshot){
        return `tile ${snapshot.isDragging ? 'dragging' : ''} ${this.isActive ? 'active' : ''}`
    }

    get isActive() {
        const activeQuestion = this.props.store.activeQuestion || {}
        return activeQuestion.id === this.props.item.id;
    }

    render() {

        const {item, index, store} = this.props
        const {activeQuestion} = store
        const webhooks = (item.webhooks||[])
        const questions = (item.questions||[])
        const isActive = activeQuestion.id === item.id
        return (
            <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={provided.draggableProps.style}
                        onClick={this.click.bind(this)}
                        className={this.className(snapshot)}
                    >
                        <div className="w100 margin_bottom_lg">
                            <ItemTypeHandler
                                item={item}
                            />
                        </div>
                        {
                            isActive &&
                            <div>

                                <div className={'archive-question float-right'} onClick={() => {
                                    const toStatus = activeQuestion.status === 'NEW' ? 'ARCHIVED' : 'NEW'
                                    this.props.changeActiveQuestionValue('status', toStatus)
                                    this.props.setActiveQuestionNext(activeQuestion, toStatus === 'NEW' ? "Restored to 'Current'" : "Question was archived")
                                }}>
                                    {activeQuestion.status === 'NEW' ? 'Archive' : 'Restore'}
                                </div>

                            </div>
                        }
                        {
                            !isActive &&
                            <div className={'tile-top-spacer'}></div>
                        }
                        {
                            isActive &&
                            <CheckoffTextInput
                                item={item}
                                label={''}
                                field={'title'}
                                className={`title-input-box ${isActive ? 'active' : 'inactive'}`}
                            />
                        }
                        {
                            !isActive &&
                            <div className={'title-input-box padded_md'}>{item.title}</div>
                        }
                        {
                            isActive &&
                            <div>
                                <div className={'tile-editor'}>
                                    <ReactQuill
                                        theme="snow"
                                        value={item.description || ''}
                                        onChange={(value)=>this.props.setQuestion(item.id, 'description', value)}
                                    />
                                </div>


                                <Questioner
                                    item={item}
                                />

                                <WebHooker
                                    item={item}
                                />

                            </div>
                        }
                        {
                            !isActive &&
                            <div>
                                <div className={'tile-description padded_md ql-editor'} dangerouslySetInnerHTML={{ __html: item.description }} />
                                {
                                    (questions.length > 0 || webhooks.length > 0) &&
                                    <div className="display-box">
                                        {
                                            questions.map((q)=>{
                                                return (
                                                    <div className={'read-only-webhooks'}>
                                                        {q.question} <span className="margin_left_xs">[{q.name}]</span>
                                                    </div>
                                                )
                                            })
                                        }
                                        {
                                            webhooks.map((w)=>{
                                                return (
                                                    <div className={'read-only-webhooks'}>
                                                        {w.type} – {w.route}
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                }
                            </div>
                        }
                    </div>
                )}
            </Draggable>
        )
    }

    get tileText(){
        const { item } = this.props
        const questionText = !item.question_text.includes('Lorem ipsum dolor sit amet, consectetur') ? item.question_text : ''
        return `${item.title} ${questionText}`.substring(0, 125)
    }

}

function mapStateToProps(state) {
    return {
        store: state
    };
}

export default connect(mapStateToProps, {
    setActiveQuestion,
    setQuestion,
    changeActiveQuestionValue,
    setActiveQuestionNext,
})(Tile)
