import React, {Component} from 'react'
import StopTypingInput from "../shared/StopTypingInput";
//import {patchDeployment} from "../services/deployment";

class DeploymentListing extends Component {

    constructor(props) {
        super(props)
        this.state = {
            deployment: props.deployment,
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.deployment.id !== this.props.deployment.id) {
            this.setState({deployment: this.props.deployment})
        }
    }

    render() {
        const {deployment} = this.state
        console.log(deployment.name.includes('Test'))
        return (
            <div className="deployment-listing no-select" key={this.props.index} onClick={() => window.location = `/playbook/${deployment.playbook_id}/deployment/${deployment.id}`}>
                <div className="w100" >
                    <StopTypingInput
                        id={deployment.id}
                        // onFocus={()=>this.props.updatePlaybook({ id: playbook.id })}
                        className={'w100 category-name'}
                        value={deployment.name}
                        timeForStop={400}
                        defaultValue={deployment.name}
                        onTextStop={async (text) => {
                            const newDeployment = {
                                ...this.state.deployment,
                                name: text,
                            }

                           // patchDeployment(newDeployment, this.props)

                            this.setState({
                                deployment: newDeployment
                            })
                        }}
                    />
                    {
                        this.state.expanded &&
                        <div>
                            {JSON.stringify(this.props.deployment)}
                        </div>
                    }
                    {
                        deployment.name.includes('Test') &&
                        <div className="action-button test">Republish to Test</div>
                    }
                    {
                        deployment.name.includes('Production') &&
                        <div className="action-button prod">Republish to Production</div>
                    }
                </div>
            </div>
        )
    }

}

export default DeploymentListing
