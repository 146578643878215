import React, {Component} from 'react'
import {connect} from 'react-redux';
import { PieChart, Pie, Sector, Cell } from 'recharts';
import renderActiveShape from './Doughnut/ActiveShape'

const COLORS = ['#14d990', '#aaaaaa', '#ffb300', "#00e6a6"];

class Doughnut extends Component {

    constructor(props) {
        super(props)
        this.count = this.count.bind(this)
        this.state = {
            activeIndex: 0,
        }
    }

    onPieEnter = (data, index) => {
        this.setState({
            activeIndex: index,
        });
    };

    get data(){
        return [
            { name: 'Completed', value: this.count('Completed')},
            { name: 'Unstarted', value: this.count('Unstarted')},
            { name: 'Abandoned', value: this.count('Abandoned')},
            { name: 'Sent', value: this.count('Sent')},
        ]
    }

    count(completionStatus){
        return this.props.respondents.filter((r)=>r.completionStatus === completionStatus).length
    }

    render() {
        return (
            <PieChart width={700} height={370}>
                <Pie
                    activeIndex={this.state.activeIndex}
                    activeShape={renderActiveShape}
                    data={this.data}
                    cx={400}
                    cy={200}
                    innerRadius={50}
                    outerRadius={90}
                    fill="#8884d8"
                    dataKey="value"
                    onMouseEnter={this.onPieEnter}
                >
                    {
                        this.data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                    }
                </Pie>
            </PieChart>
        );
    }

}

function mapStateToProps(state) {
    return {
        store: state
    };
}

export default connect(mapStateToProps, {})(Doughnut)


