import React, {Component} from 'react'
import {connect} from 'react-redux';
import Run from "./Run";

class RunsListWithStatus extends Component {


    constructor(props) {
        super(props)
        this.searchFilter = this.searchFilter.bind(this)
    }

    searchFilter(r) {
        if (this.props.search) {
            return `${r.name} ${r.id}`.toLowerCase().includes(this.props.search.toLowerCase())
        } else {
            return true
        }
    }

    get runs(){
        const status = this.props.store.archived ? 'ARCHIVED' : 'NEW'
        return this.props.store.runs.filter((r)=>r.status===status)
    }

    get allQuestions(){
        const {store} = this.props
        return store.questions.filter((q)=>q.status ==='NEW').map((q,i)=>({...q, index: i}))
    }

    get questionsBySection(){
        const sections = this.allQuestions.filter((q)=>q.type==='header').reverse()

        let lastIndex = null
        return sections.map((s,i)=>{

            const section = {
                ...s,
                questions: this.allQuestions.filter((q)=>{
                    const hasNotBeenAddedToPriorSection = (lastIndex == null || q.index < lastIndex)
                    return (q.type==='item' && q.index > s.index && hasNotBeenAddedToPriorSection)
                }).reverse()

            }

            lastIndex = s.index
            return section
        })
    }

    get questionsBySectionWithFormatting(){
        const sections = this.questionsBySection
        return sections.map((s,i)=>{
            return {
                ...s,
                questions: i===0 ? [ { title: 'Complete', id: null }, ...s.questions] : s.questions
            }
        })

    }

    render() {
        const {run, index, store} = this.props
        console.log(this.runs)
        return (
            <div>
                {
                    this.questionsBySectionWithFormatting.map((section,i)=>{
                        return (
                            <div className={'run-section'} key={`section-${i}`}>
                                <div className="section-title">{section.title}</div>
                                {this.questions(section.questions)}
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    questions(questions){
        return (
            <div className={'w100'}>
                {
                    questions
                        .map((q, i)=>(
                            <div>
                                <div className="checkoff-status">
                                    <div className="status-title">
                                        {q.title}
                                    </div>
                                </div>
                                {
                                    this.runs
                                        .filter((r)=>{
                                            const nextCheckoffId = (r.nextCheckoff||{}).id
                                            return (nextCheckoffId == q.id)
                                        })
                                        .filter(this.searchFilter)
                                        .map((run, index) => {
                                            return (
                                                <Run
                                                    run={run}
                                                    index={index}
                                                />
                                            )
                                        })
                                }
                            </div>
                        ))
                }
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        store: state
    };
}

export default connect(mapStateToProps, {
})(RunsListWithStatus)
