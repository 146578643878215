import React, {Component} from 'react'
import {connect} from 'react-redux';
import {changeActiveQuestionValue, } from "./redux/actions";

class Toggle extends Component {

    render() {
        const bool = this.props.store.activeQuestion[this.props.field]
        return (
            <img
                src={bool ? window.view_data.imgs.toggle_right : window.view_data.imgs.toggle_left}
                width={40}
                className={this.props.className}
                onClick={()=>this.props.changeActiveQuestionValue(this.props.field,!bool)}
            />
        )
    }

}

function mapStateToProps(state) {
    return {
        store: state
    };
}

export default connect(mapStateToProps, {
    changeActiveQuestionValue,
})(Toggle)
