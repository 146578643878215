import React, {Component} from 'react'
import {connect} from 'react-redux';
import CheckoffItem from './item/CheckoffItem'
import HeaderItem from './item/HeaderItem'
import QuestionValueItem from './item/QuestionValueItem'

class Checklist extends Component {


    constructor(props) {
        super(props)

    }


    itemComponent(item){
        switch(item.type){
            case 'item':
                return (<CheckoffItem item={item} />)
            case 'value':
                return (<QuestionValueItem item={item} />)
            case 'header':
                return (<HeaderItem item={item} />)
        }
    }

    render() {
        const {run, playbook } = this.props.store


        return (
            <div>
                <hr/>
                {
                    this.props.store.questions.map((q, i)=>(
                        <div key={i}>
                            {this.itemComponent(q)}
                        </div>
                    ))
                }
            </div>
        )
    }



}

function mapStateToProps(state) {
    return {
        store: state
    };
}

export default connect(mapStateToProps, {
})(Checklist)
