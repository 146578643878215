import React, {Component} from 'react'
import {connect} from 'react-redux';

class Collapser extends Component {

    constructor(props) {
        super(props)
        this.state = {
            expanded: false
        }
    }

    render() {
        if (this.state.expanded) {
            return (
                <label className={'w100 bold-header'}>
                    {this.props.label}
                    <div className={'close-addable'} onClick={() => this.setState({ expanded: false })}>
                        <div className={'addable-minus'} style={{ backgroundColor: this.props.iconColor }}><span>-</span></div>
                    </div>
                    <div className={'text-input-boxes'}>
                        {this.props.children}
                    </div>
                </label>
            )
        } else {
            return (
                <label className={'w100 bold-header'} onClick={() => this.setState({ expanded: true })}>
                    <div className={'addable-plus'}  style={{ backgroundColor: this.props.iconColor }} ><span>+</span></div> {this.props.label}
                </label>
            )
        }
    }

}

function mapStateToProps(state) {
    return {
        store: state
    };
}

export default connect(mapStateToProps, {
})(Collapser)
