import React, {Component} from 'react'
import {connect} from 'react-redux';
import {changeActiveQuestionValue} from "./redux/actions";

class TextInput extends Component {

    changeText(e) {
        this.props.changeActiveQuestionValue(this.props.field, e.target.value)
    }

    render() {
        return (
                <label className={'w100 bold-header'}>
                    {this.props.label}
                    <div className={'text-input-boxes'}>
                        {
                            this.props.textarea &&
                            <textarea
                                className={'w100'}
                                value={this.props.store.activeQuestion[this.props.field]}
                                onChange={this.changeText.bind(this)}
                            />
                        }
                        {
                            !this.props.textarea &&
                            <input
                                type={'text'}
                                className={'w100'}
                                value={this.props.store.activeQuestion[this.props.field]}
                                onChange={this.changeText.bind(this)}
                            />
                        }
                    </div>
                </label>
        )
    }

}

function mapStateToProps(state) {
    return {
        store: state
    };
}

export default connect(mapStateToProps, {
    changeActiveQuestionValue
})(TextInput)
