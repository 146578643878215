export const patchProposal = async (proposal, props)=>{
    const response = await fetch(`/proposal/${proposal.id}`, {
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRF-Token': props.authenticationToken,

        },
        body: JSON.stringify({
            ...proposal,
        }),
    })

    return await response.json()
}

export const getConfig = () => {
    return {
        "onetime-sdc": {
            "markupPercent": 0,
            "baseCost": 24910,
            "duration": {
                "defaultValue": "1",
                "1": 0
            },
            "zone_count": {
                "defaultValue": "1",
                "1": 0,
                "2": 21900,
                "3": 43800,
            },
            "language_count": {
                "defaultValue": "1",
                "1": 0,
                "per": 0,
            },
            "patient_count": {
                "defaultValue": "0-25",
                "0-25": 0,
                "26-50": 0,
                "51-200": 0,
                "201-1000": 0,
                "1001-5000": 15000,
                "5000+": 50000,
            },
            "integration_count": {
                "defaultValue": "0",
                "0": 0
            },
            "support_hrs": {
                "defaultValue": "standard",
                "standard": 0
            },
            "site_count": {
                "defaultValue": "0-15",
                "0-15": 0
            },
            "coordination_hours": {
                "defaultValue": "0-20",
                "0-20": 0
            },
            "training":     {
                "defaultValue": "standard",
                "standard": 0
            },
        },
        "sdc": {
            "markupPercent": 0,
            "baseCost": 14980,
            "duration": {
                "defaultValue": "1",
                "1": 0
            },
            "zone_count": {
                "defaultValue": "1",
                "1": 0,
                "2": 21900,
                "3": 43800,
            },
            "language_count": {
                "defaultValue": "1",
                "1": 0,
                "per": 0,
            },
            "patient_count": {
                "defaultValue": "0-25",
                "0-25": 0,
                "26-50": 0,
                "51-200": 0,
                "201-1000": 0,
                "1001-5000": 15000,
                "5000+": 50000,
            },
            "integration_count": {
                "defaultValue": "0",
                "0": 0
            },
            "support_hrs": {
                "defaultValue": "standard",
                "standard": 0
            },
            "site_count": {
                "defaultValue": "0-15",
                "0-15": 0
            },
            "coordination_hours": {
                "defaultValue": "0-20",
                "0-20": 0
            },
            "training":     {
                "defaultValue": "standard",
                "standard": 0
            },
        },
        "epro": {
            "markupPercent": 0,
            "baseCost": 114500,
            "duration": {
                "defaultValue": "18",
            },
            "zone_count": {
                "defaultValue": "1",
                "1": 0,
                "2": 21000,
                "2+": 21000,
            },
            "patient_count": {
                "defaultValue": "0-25",
                "0-25": 0,
            },
            "language_count": {
                "defaultValue": "1",
                "1": 0,
                "per": 3500,
            },
            "site_count": {
                "defaultValue": "0-15",
                "0-15": 0,
                "16-30": 12000,
                "31-70": 21000,
                "71-120": 33000,
            },
            "training": {
                "defaultValue": "standard",
                "standard": 0,
                "3-virtual": 7000,
                "individualized-site": 15000,
            },
            "integration_count": {
                "defaultValue": "1",
                "1": 0,
                "2": 17000,
                "2+": 17000,
            },
            "coordination_hours": {
                "defaultValue": "0-20",
                "0-20": 0,
                "21-50": 8000,
            },
            "support_hrs": {
                "defaultValue": "standard",
                "meta-note": "calculates as addon per month",
                "standard": 800,
                "extended": 1100,
                "all-hours-business-days": 2900,
                "all-hours": 3700,
            }
        },
        "ecoa": {
            "markupPercent": 30,
            "baseCost": 124500,
            "duration": {
                "defaultValue": "18",
            },
            "zone_count": {
                "defaultValue": "1",
                "1": 0,
                "2": 24000,
                "2+": 24000,
            },
            "patient_count": {
                "defaultValue": "0-25",
                "0-25": 0,
            },
            "language_count": {
                "defaultValue": "1",
                "1": 0,
                "per": 3500,
            },
            "site_count": {
                "defaultValue": "0-15",
                "0-15": 0,
                "16-30": 12000,
                "31-70": 21000,
                "71-120": 33000,
            },
            "training": {
                "defaultValue": "standard",
                "standard": 0,
                "3-virtual": 7000,
                "individualized-site": 15000,
            },
            "integration_count": {
                "defaultValue": "1",
                "1": 0,
                "2": 17000,
                "2+": 15000,
            },
            "coordination_hours": {
                "defaultValue": "0-20",
                "0-20": 0,
                "21-50": 12000,
            },
            "support_hrs": {
                "defaultValue": "standard",
                "meta-note": "calculates as addon per month",
                "standard": 0,
                "extended": 1100,
                "all-hours-business-days": 2100,
                "all-hours": 3700,
            }
        },
    }
}
