import React, {Component} from 'react'
import {connect} from 'react-redux';
import MultiButton from "./MultiButton";
import TwoAnswer from "./TwoAnswer";
import NumericRating from "./NumericRating";
import OpenEnded from "./OpenEnded";
import Number from "./Number";
import Sign from "./Sign";

class AnswerButtons extends Component {

    render() {
        return (
            <div className={'w100'}>
                {
                    this.props.store.activeQuestion.question_type === 'informational' &&
                    <div></div>
                }
                {
                    this.props.store.activeQuestion.question_type === 'multi-button' &&
                    <MultiButton />
                }
                {
                    this.props.store.activeQuestion.question_type === 'two-answer' &&
                    <TwoAnswer />
                }
                {
                    this.props.store.activeQuestion.question_type === 'long-answer' &&
                    <OpenEnded />
                }
                {
                    this.props.store.activeQuestion.question_type === 'numeric-rating' &&
                    <NumericRating />
                }
                {
                    this.props.store.activeQuestion.question_type === 'number' &&
                    <Number />
                }
                {
                    this.props.store.activeQuestion.question_type === 'sign' &&
                    <Sign />
                }
                {
                    this.props.store.activeQuestion.question_type === 'confirmation' &&
                    <div></div>
                }
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        store: state
    };
}

export default connect(mapStateToProps, {
})(AnswerButtons)
