import React, {Component} from 'react'
import {connect} from 'react-redux';

class HeaderItem extends Component {


    constructor(props) {
        super(props)

    }

    render() {
        const {item} = this.props

        return (
            <div className={'header-item default-item'}>
                {item.title}
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        store: state
    };
}

export default connect(mapStateToProps, {
})(HeaderItem)

